import { OpenKeycloakInPopupType } from '@zelros/standalone-interfaces';
import Keycloak, {
    type KeycloakConfig,
    type KeycloakInitOptions,
    KeycloakLoginOptions,
} from 'keycloak-js';
import {
    type Accessor,
    batch,
    createContext,
    createSignal,
    onMount,
    type ParentComponent,
    Show,
    untrack,
    useContext,
} from 'solid-js';
import { FullscreenLoading } from '~/components/FullscreenLoading';
import { useBootstrapConfig } from '~/config/BootstrapConfigProvider';
import { AUTH_URL } from '~/Constants';
import { useLogging } from '~/logging/LoggingContext';

const KEYCLOAK_INIT_OPTIONS: KeycloakInitOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: true,
    enableLogging: import.meta.env.MODE === 'development',
};

const MIN_VALIDITY = 30; // seconds

/**
 * Message sent by the login-success.html page after being redirected to it by Keycloak
 */
const LOGIN_SUCCESS_MESSAGE = 'login_success';

interface AuthContextType {
    authToken: Accessor<string>;
    authHeader: Accessor<{ authorization?: string }>;
    authenticated: Accessor<boolean>;
}

const AuthContext = createContext<AuthContextType>();

const KEY = 'AUTH_TOKENS';

/**
 * Exposes the authentication state to the application.
 *
 * The AuthProvider initializes Keycloak and manages the authentication state (refreshes tokens, etc.).
 *
 * Components making API calls should use the `useAuth` hook to get the authentication state
 * and the authHeader() accessor to get the Authorization header.
 *
 * @param props
 * @constructor
 */
export const AuthProvider: ParentComponent = (props) => {
    const bootstrapConfig = useBootstrapConfig();
    const { log } = useLogging();

    log('AuthProvider: Initializing AuthProvider...');

    const [authToken, setAuthToken] = createSignal<string>('');
    const [authenticated, setAuthenticated] = createSignal<boolean>(false);

    const authHeader = () => ({
        authorization: `Bearer ${untrack(authToken)}`,
    });

    const keycloakConfig: KeycloakConfig = {
        url: AUTH_URL,
        realm: bootstrapConfig.authentication.options.realm,
        clientId: bootstrapConfig.authentication.options.clientId,
    };

    const keycloak = new Keycloak(keycloakConfig);

    keycloak.onTokenExpired = async () => {
        log('AuthProvider: Token expired');

        let valid: boolean;

        try {
            valid = await keycloak.updateToken(MIN_VALIDITY);
        } catch (e) {
            log(`AuthProvider: Failed to refresh token: ${e}`);
            valid = false;
        }

        log(`AuthProvider: Token refreshed: ${valid}`);

        if (!valid) {
            removeTokens();
            return login();
        }
    };

    keycloak.onAuthSuccess = () => {
        log('AuthProvider: Auth success');
        updateTokens({
            token: keycloak.token as string,
            refreshToken: keycloak.refreshToken as string,
        });
    };

    keycloak.onAuthRefreshSuccess = () => {
        log('AuthProvider: Auth refresh success');
        updateTokens({
            token: keycloak.token as string,
            refreshToken: keycloak.refreshToken as string,
        });
    };

    const initKeycloak = async () => {
        log('AuthProvider: Initializing Keycloak...');
        try {
            // Try retrieving tokens from localStorage
            let tokens: { token?: string; refreshtoken?: string } = {};
            const rawTokens = localStorage.getItem(KEY);
            if (rawTokens) {
                tokens = JSON.parse(rawTokens);
            }

            /**
             * Override the original "redirect_uri" to redirect to our /app/login-success.html page
             * if the authentication must happen in a popup or tab
             */
            let redirectUri: string | undefined = undefined;
            if (
                bootstrapConfig.authentication.iframe.openKeycloakInPopup !==
                OpenKeycloakInPopupType.None
            ) {
                redirectUri = new URL(
                    '/app/login-success.html',
                    window.location.origin,
                ).toString();
            }

            const result = await keycloak.init({
                ...KEYCLOAK_INIT_OPTIONS,
                ...tokens,
                redirectUri,
                timeSkew: 0,
            });

            log('AuthProvider: Keycloak initialized');

            if (result) {
                updateTokens({
                    token: keycloak.token as string,
                    refreshToken: keycloak.refreshToken as string,
                });
            } else {
                return login();
            }
        } catch (error) {
            console.error('AuthProvider: Failed to initialize Keycloak', error);
        }
    };

    onMount(initKeycloak);

    function updateTokens({
        token,
        refreshToken,
    }: {
        token: string;
        refreshToken: string;
    }) {
        log(`AuthProvider: Updating tokens...`, keycloak.isTokenExpired());

        // Store tokens in localStorage
        localStorage.setItem(KEY, JSON.stringify({ token, refreshToken }));

        // Update state
        batch(() => {
            setAuthenticated(true);
            setAuthToken(token);
        });

        // Relaunch timer
        //setUpdateTokenTimer();
    }

    function removeTokens() {
        // Update state
        batch(() => {
            setAuthenticated(false);
            setAuthToken('');
        });

        // Remove tokens from localStorage
        localStorage.removeItem(KEY);
    }

    if (
        bootstrapConfig.authentication.iframe.openKeycloakInPopup !==
        OpenKeycloakInPopupType.None
    ) {
        // We need to override the login method to open a new tab/window instead of performing a redirection
        keycloak.login = async (options: KeycloakLoginOptions = {}) => {
            log('AuthProvider: Opening Keycloak in new tab/window...');

            /**
             * When the Zelros App is positioned in an iframe and when using the SSO
             * we often encounter an issue where the SAML or OpenID Connect identity provider
             * doewn't allow being presented in an iframe (because of the "X-Frame-Options: deny" HTTP header).
             *
             * In that case, we open the Keycloak login page in a separate window
             * (popup or tab depending on the default behavior of the browser).
             * Keycloak ultimately redirects to the /app/login-success.html page
             * which sends us back the Keycloak infos (state, sessions_state, request code).
             * We then update the fragment of the URL of the current Zelros App page
             * and reload the app.
             * Upon reload, the Keycloak SDK will log us in just like with the regular flow
             * where Keycloak would redirect to the assistant will infos in the URL fragment.
             */

            const loginUrl = keycloak.createLoginUrl(options);

            const windowOptions =
                bootstrapConfig.authentication.iframe.openKeycloakInPopup ===
                OpenKeycloakInPopupType.Window
                    ? `modal=yes,toolbar=no,location=no,status=no,menubar=no,width=100,height=100,top=${
                          screen.height - 200
                      },left=${screen.width - 200}`
                    : '';
            const loginWindow = window.open(
                loginUrl,
                'Zelros Login',
                windowOptions,
            );

            const url = new URL(window.location.href);

            // Listen to messages from the new tab / window
            const receiveMessage = (event: any) => {
                if (event.origin === url.origin) {
                    if (
                        event.data &&
                        typeof event.data === 'object' &&
                        event.data.type === LOGIN_SUCCESS_MESSAGE
                    ) {
                        log('AuthProvider: Hash received');
                        window.removeEventListener('message', receiveMessage);
                        if (loginWindow) {
                            log(
                                'AuthProvider: Closing Keycloak callback popup...',
                            );
                            loginWindow.close();
                        }

                        log(
                            'AuthProvider: Reloading app with Keycloak hash...',
                        );
                        window.location.hash = event.data.data.hash;
                        window.location.reload();
                    }
                }
                // Ignore other messages
            };

            window.addEventListener('message', receiveMessage);
        };
    }

    const login = () => {
        const url = new URL(window.location.href);

        const options: KeycloakLoginOptions = {};

        // We're outside the Router, so we can't use useSearchParams to retrieve the querystring
        const defaultIdp = url.searchParams.get('defaultIdp');
        if (defaultIdp) {
            options.idpHint = defaultIdp;
        } else if (bootstrapConfig.authentication.defaultIdp) {
            options.idpHint = bootstrapConfig.authentication.defaultIdp;
        }

        return keycloak.login(options);
    };

    return (
        <AuthContext.Provider value={{ authenticated, authToken, authHeader }}>
            <AuthContent>{props.children}</AuthContent>
        </AuthContext.Provider>
    );
};

const AuthContent: ParentComponent = (props) => {
    const { authenticated } = useAuth();

    return (
        <Show when={authenticated()} fallback={<FullscreenLoading />}>
            {props.children}
        </Show>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
