import { LifeEventDisplay } from './life-event-display.model';

export interface LifeEventDisplayDTO {
    id: string;
    resource_id: string; // Deprecated, use resource.id instead
    resource: {
        id: string;
        created_at: string;
        updated_at: string;
    };
    priority: number;
    title: string;
    image_url: string | null;
    topics: string[];
    product_settings: {
        id: string;
        url: string | null;
        /**
         * Kept for retrocompatibility
         * @deprecated Use `catch_phrase` instead
         */
        additional_info: string | null;
        catch_phrase: string | null;
    }[];
    data: Record<string, any>;
    products: LifeEventDisplayProductDTO[];
    rl: {
        prediction_id: string;
        score: number;
    } | null;
}

export interface LifeEventDisplayProductDTO {
    id: string;
    name: string;
}

export function toLifeEventDisplayDTO(
    display: LifeEventDisplay,
): LifeEventDisplayDTO {
    return {
        id: display.id,
        resource_id: display.lifeEvent.id,
        resource: {
            id: display.lifeEvent.id,
            created_at: display.lifeEvent.createdAt.toISOString(),
            updated_at: display.lifeEvent.updatedAt.toISOString(),
        },
        priority: display.lifeEvent.priority,
        title: display.title,
        image_url: display.imageUrl,
        topics: display.lifeEvent.topics,
        product_settings: display.lifeEvent.productSettings.map((ps) => ({
            id: ps.id,
            url: ps.url,
            additional_info: ps.catchPhrase,
            catch_phrase: ps.catchPhrase,
        })),
        data: display.data,
        products: display.products.map((product) => ({
            id: product.id,
            name: product.name,
        })),
        rl: display.rl
            ? {
                  prediction_id: display.rl.predictionId,
                  score: display.rl.score,
              }
            : null,
    };
}

export function lifeEventDisplayFromDTO(
    dto: LifeEventDisplayDTO,
): LifeEventDisplay {
    return {
        id: dto.id,
        lifeEvent: {
            id: dto.resource.id,
            priority: dto.priority,
            topics: dto.topics,
            productSettings: dto.product_settings.map((ps) => ({
                id: ps.id,
                url: ps.url,
                catchPhrase: ps.catch_phrase,
            })),
            createdAt: new Date(dto.resource.created_at),
            updatedAt: new Date(dto.resource.updated_at),
        },
        title: dto.title,
        imageUrl: dto.image_url,
        data: dto.data,
        products: dto.products.map((product) => ({
            id: product.id,
            name: product.name,
        })),
        rl: dto.rl
            ? {
                  predictionId: dto.rl.prediction_id,
                  score: dto.rl.score,
              }
            : null,
    };
}

export interface LifeEventDisplayForActivityDTO {
    id: string;
    resource_id: string;
    title: string;
}

export function toLifeEventDisplayForActivityDTO(
    display: LifeEventDisplay,
): LifeEventDisplayForActivityDTO {
    return {
        id: display.id,
        resource_id: display.lifeEvent.id,
        title: display.title,
    };
}
