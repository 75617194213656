/* @refresh reload */
import { render } from 'solid-js/web';
import { CachingProvider } from '~/caching/CachingContext';
import { Analytics } from '~/config/Analytics';
import { BootstrapConfigProvider } from '~/config/BootstrapConfigProvider';
import { LoggingProvider } from '~/logging/LoggingContext';
import { MessagingProvider } from '~/messaging/MessagingContext';
import { TrackingProvider } from '~/tracking/TrackingContext';
import App from './App';
import './polyfills';
import './styles/index.css';
import { AuthProvider } from './auth/AuthContext';
import { ConfigProvider } from './config/ConfigProvider';
import { TranslationProvider } from './translation/TranslationProvider';

const root = document.getElementById('root');

if (!root) {
    throw new Error('Root element not found');
}
render(
    () => (
        <TranslationProvider>
            <BootstrapConfigProvider>
                <LoggingProvider>
                    <AuthProvider>
                        <CachingProvider>
                            <ConfigProvider>
                                <MessagingProvider>
                                    <TrackingProvider>
                                        <App />
                                    </TrackingProvider>
                                </MessagingProvider>
                            </ConfigProvider>
                        </CachingProvider>
                    </AuthProvider>
                    <Analytics />
                </LoggingProvider>
            </BootstrapConfigProvider>
        </TranslationProvider>
    ),
    root,
);
