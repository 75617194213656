import { Trans, TransProvider } from '@mbarzda/solid-i18next';
import { MetaProvider, Title } from '@solidjs/meta';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend/esm';
import LocalStorageBackend from 'i18next-localstorage-backend';
import type { ParentComponent } from 'solid-js';

export type SUPPORTED_LANGUAGES = 'fr' | 'en';

export const TranslationProvider: ParentComponent = (props) => {
    const instance = i18next.createInstance();
    instance.use(LanguageDetector).use(ChainedBackend);
    const i18nOptions = {
        debug: import.meta.env.MODE === 'development',
        interpolation: {
            escapeValue: true,
        },
        fallbackLng: undefined,
        ns: 'translation',
        keySeparator: '.',
        order: ['localStorage', 'navigator'],
        lookupLocalStorage: 'i18nextLng',
        caches: ['localStorage'],
        supportedLngs: ['fr', 'en'] as SUPPORTED_LANGUAGES[],
        load: 'currentOnly' as const,
        backend: {
            backends: [LocalStorageBackend, HttpBackend],
            backendOptions: [
                {
                    expirationTime: 30 * 60 * 1000, // 30 minutes
                },
                {
                    loadPath: '/api/standalone/translations/{{lng}}.json',
                },
            ],
        },
    };

    return (
        <TransProvider instance={instance} options={i18nOptions}>
            <MetaProvider>
                <Title>
                    <Trans key="common.page_title" />
                </Title>
            </MetaProvider>
            {props.children}
        </TransProvider>
    );
};
