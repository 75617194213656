import { useTransContext } from '@mbarzda/solid-i18next';
import { MetaProvider, Title, Link } from '@solidjs/meta';
import { Route, Router } from '@solidjs/router';
import { lazy, Show } from 'solid-js';
import { useConfig } from '~/config/ConfigProvider';
import { CustomerStateProvider } from '~/thread/customer/state/StateContext';
import { i18n } from './i18n';
import { Layout } from './layout/Layout';
import { RecentThreadsProvider } from './thread/recent-threads/RecentThreadsContext';
import { ThreadProvider } from './thread/ThreadContext';
import { ThreadSocketProvider } from './thread/ThreadSocketContext';
import { OpportunitiesProvider } from './thread/view/opportunities/OpportunitiesContext';
import { WarningsProvider } from './thread/view/warnings/WarningsContext';

const ThreadContainer = lazy(() => import('./thread/ThreadContainer'));

export default function App() {
    const [, { getI18next }] = useTransContext();
    const { setLanguage } = i18n;
    setLanguage(getI18next().language);

    const config = useConfig();

    return (
        <>
            <Show when={config.homepage.lookAndFeel.title}>
                <MetaProvider>
                    <Title>{config.homepage.lookAndFeel.title}</Title>
                    <Show
                        when={config.homepage.lookAndFeel.searchBar.logo}
                        fallback={
                            <Link
                                rel="icon"
                                type="image/svg+xml"
                                href="favicon.svg"
                            />
                        }
                    >
                        {(logo) => (
                            <Link
                                rel="icon"
                                type="image/svg+xml"
                                href={`data:image/svg+xml;base64,${btoa(logo())}`}
                            />
                        )}
                    </Show>
                </MetaProvider>
            </Show>
            <RecentThreadsProvider>
                <ThreadSocketProvider>
                    <ThreadProvider>
                        <WarningsProvider>
                            <OpportunitiesProvider>
                                <CustomerStateProvider>
                                    <Router root={Layout} base="/app">
                                        <Route
                                            path=""
                                            component={ThreadContainer}
                                        />
                                        <Route
                                            path="/threads/:threadId"
                                            component={ThreadContainer}
                                        />
                                    </Router>
                                </CustomerStateProvider>
                            </OpportunitiesProvider>
                        </WarningsProvider>
                    </ThreadProvider>
                </ThreadSocketProvider>
            </RecentThreadsProvider>
        </>
    );
}
