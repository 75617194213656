declare global {
    interface Window {
        // clarity analytics
        clarity: { q: unknown[] };
    }
}

export const initClarity = (id: string) => {
    window.clarity =
        window.clarity ||
        ((...args: unknown[]) => {
            window.clarity.q = window.clarity.q || [];
            window.clarity.q.push(args);
        });
    const scriptElement = document.createElement('script') as HTMLScriptElement;
    scriptElement.async = true;
    scriptElement.src = `https://www.clarity.ms/tag/${id}`;
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode?.insertBefore(scriptElement, firstScript);
};
