import { io, type Socket } from 'socket.io-client';
import {
    type Accessor,
    createContext,
    createSignal,
    type JSX,
    onCleanup,
    onMount,
    useContext,
} from 'solid-js';
import { useAuth } from '~/auth/AuthContext';
import { useConfig } from '~/config/ConfigProvider';
import { BASE_URL } from '~/Constants';
import { useLogging } from '~/logging/LoggingContext';

interface ThreadSocketContextType {
    socket: Accessor<Socket | undefined>;
}

const ThreadSocketContext = createContext<ThreadSocketContextType>();

export const ThreadSocketProvider = (props: { children: JSX.Element }) => {
    const { log } = useLogging();
    const { authToken } = useAuth();
    const config = useConfig();

    const baseUrl = `${BASE_URL}/api/standalone-ws`;

    const [socket, setSocket] = createSignal<Socket>();

    const transports = ['polling'];
    if (config.magicAnswer.socketio.websockets) {
        transports.unshift('websocket');
    }

    onMount(async () => {
        // Initiate the socket connection
        log('ThreadSocketProvider: Initiating thread socket connection...');
        const socket = io({
            path: `${baseUrl}/socket.io`,
            secure: true,
            auth: (cb) => {
                cb({
                    token: authToken(),
                });
            },
            transports,
        });

        // Bind event listeners
        socket.on('connect', () => {
            log('ThreadSocketProvider: Connected to thread socket');
            setSocket(socket);
        });
        socket.on('disconnect', () => {
            log('ThreadSocketProvider: Disconnected from thread socket');
            setSocket(undefined);
        });
        socket.on('error', (error: unknown) => {
            log('ThreadSocketProvider: Error on thread socket', error);
        });

        onCleanup(() => {
            log('ThreadSocketProvider: Cleaning up thread socket connection');
            socket.disconnect();
        });
    });

    return (
        <ThreadSocketContext.Provider value={{ socket }}>
            {props.children}
        </ThreadSocketContext.Provider>
    );
};

export const useThreadSocket = () => {
    const context = useContext(ThreadSocketContext);
    if (!context) {
        throw new Error(
            'useThreadSocket must be used within a ThreadSocketProvider',
        );
    }
    return context;
};
