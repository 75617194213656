import { DecisionDTO, decisionFromDTO, toDecisionDTO } from '../../../decision';
import {
    StandardizedPropensityDTO,
    standardizedPropensityFromDTO,
    toStandardizedPropensityDTO,
} from '../../../propensity';
import {
    QuestionDisplayDTO,
    QuestionDisplayForActivityDTO,
    questionDisplayFromDTO,
    toQuestionDisplayDTO,
    toQuestionDisplayForActivityDTO,
} from '../question';
import {
    SellingPointDisplayDTO,
    SellingPointDisplayForActivityDTO,
    sellingPointDisplayFromDTO,
    toSellingPointDisplayDTO,
    toSellingPointDisplayForActivityDTO,
} from '../selling-point';
import {
    LocalExplainabilityFeedback,
    Recommendation,
} from './recommendation.model';

export interface RecommendationProductDTO {
    id: string;
    /*
     * @deprecated, kept for backward compatibility with the SalesForce integration
     */
    catalog_id: string;
    external_id: string;
    name: string;
    groups: {
        id: string;
        /*
         * @deprecated, kept for backward compatibility with the SalesForce integration
         */
        catalog_id: string;
        external_id: string;
        name: string;
    }[];
    max_life_events: number;
    max_selling_points: number;
}

export interface RecommendationDTO {
    id: string;
    product: RecommendationProductDTO;
    selling_points: SellingPointDisplayDTO[];
    questions: QuestionDisplayDTO[];
    decision: DecisionDTO | null;
    propensity: StandardizedPropensityDTO;
    localExplainabilityFeedback: string | null;
}

export function toRecommendationDTO(
    recommendation: Recommendation,
): RecommendationDTO {
    return {
        id: recommendation.id,
        product: {
            id: recommendation.product.id,
            external_id: recommendation.product.externalId,
            name: recommendation.product.name,
            catalog_id: recommendation.product.id,
            groups: recommendation.product.groups.map((g) => ({
                id: g.id,
                name: g.name,
                external_id: g.externalId,
                catalog_id: g.id,
            })),
            max_life_events: recommendation.product.maxLifeEvents,
            max_selling_points: recommendation.product.maxSellingPoints,
        },
        selling_points: recommendation.sellingPointDisplays.map(
            toSellingPointDisplayDTO,
        ),
        questions: recommendation.questionDisplays.map(toQuestionDisplayDTO),
        decision: recommendation.decision
            ? toDecisionDTO(recommendation.decision)
            : null,
        propensity: toStandardizedPropensityDTO(recommendation.propensity),
        localExplainabilityFeedback: recommendation.localExplainabilityFeedback,
    };
}

export function recommendationFromDTO(dto: RecommendationDTO): Recommendation {
    return {
        id: dto.id,
        product: {
            id: dto.product.id,
            externalId: dto.product.external_id,
            name: dto.product.name,
            groups: dto.product.groups.map((g) => ({
                id: g.id,
                externalId: g.external_id,
                name: g.name,
            })),
            maxLifeEvents: dto.product.max_life_events,
            maxSellingPoints: dto.product.max_selling_points,
        },
        sellingPointDisplays: dto.selling_points.map(
            sellingPointDisplayFromDTO,
        ),
        questionDisplays: dto.questions.map(questionDisplayFromDTO),
        decision: dto.decision ? decisionFromDTO(dto.decision) : null,
        propensity: standardizedPropensityFromDTO(dto.propensity),
        localExplainabilityFeedback:
            dto.localExplainabilityFeedback as LocalExplainabilityFeedback | null,
    };
}

export interface RecommendationForActivityDTO {
    id: string;
    product: {
        id: string;
        name: string;
        groups: {
            id: string;
            name: string;
        }[];
    };
    selling_points: SellingPointDisplayForActivityDTO[];
    questions: QuestionDisplayForActivityDTO[];
}

export function toRecommendationForActivityDTO(
    recommendation: Recommendation,
): RecommendationForActivityDTO {
    return {
        id: recommendation.id,
        product: {
            id: recommendation.product.id,
            name: recommendation.product.name,
            groups: recommendation.product.groups.map((g) => ({
                id: g.id,
                name: g.name,
            })),
        },
        selling_points: recommendation.sellingPointDisplays.map(
            toSellingPointDisplayForActivityDTO,
        ),
        questions: recommendation.questionDisplays.map(
            toQuestionDisplayForActivityDTO,
        ),
    };
}
