import UpperRightArrowIcon from '@icons/arrows/arrow-up-right.svg';
import ChevronUpIcon from '@icons/arrows/chevron-up.svg';
import FileIcon from '@icons/files/document.svg';
import FlashIcon from '@icons/images/flash.svg';
import { Trans } from '@mbarzda/solid-i18next';
import type { ScoredSpan } from '@zelros/knowledge-interfaces';
import type {
    IStaticLinkSection,
    IStaticLinkSectionLink,
} from '@zelros/standalone-interfaces';
import {
    For,
    Show,
    Suspense,
    createEffect,
    createSignal,
    lazy,
} from 'solid-js';
import { useConfig } from '~/config/ConfigProvider';
import { Collapsible, Tooltip } from '../components/ui';
import styles from './SidebarList.module.css';

const DocumentSourceModal = lazy(
    () =>
        import(
            '~/thread/view/conversation/message/assistant/sources/document/DocumentSourceModal'
        ),
);

const Link = (props: {
    link: IStaticLinkSectionLink;
    onOpenSource: (source: { id: string }) => void;
}) => {
    return (
        <Show
            when={props.link.type === 'knowledge'}
            fallback={
                <a
                    href={props.link.uri}
                    target="_blank"
                    rel="noreferrer"
                    class={`${styles.elementLink} truncate text-left hover:undeline hover:text-main-600 hover:font-semibold text-sm`}
                >
                    <UpperRightArrowIcon
                        class={styles.elementLinkIcon}
                        aria-label="link"
                    />
                    {props.link.title}
                </a>
            }
        >
            <button
                type="button"
                onClick={() => {
                    props.onOpenSource({
                        id: props.link.uri,
                    });
                }}
                class={`${styles.elementLink} truncate text-left hover:undeline hover:text-main-600 hover:font-semibold text-sm`}
            >
                <FileIcon class={styles.elementLinkIcon} aria-label="link" />
                {props.link.title}
            </button>
        </Show>
    );
};

const LinkSection = (props: {
    section: IStaticLinkSection;
    onOpenSource: (source: { id: string }) => void;
}) => {
    const expandedLocalStorageValue = localStorage.getItem('static-links');
    const expandeds = expandedLocalStorageValue
        ? JSON.parse(expandedLocalStorageValue)
        : {};
    const isExpanded =
        expandeds[props.section.title] !== undefined
            ? expandeds[props.section.title]
            : props.section.expanded !== undefined
            ? props.section.expanded
            : true;

    const [expanded, setExpanded] = createSignal(isExpanded);

    createEffect(() => {
        expandeds[props.section.title] = expanded();
        localStorage.setItem('static-links', JSON.stringify(expandeds));
    });

    return (
        <Show when={props.section.links.length > 0}>
            <Collapsible.Root
                lazyMount
                onOpenChange={() => setExpanded(true)}
                onExitComplete={() => setExpanded(false)}
                open={expanded()}
            >
                <Collapsible.Trigger
                    class={`${styles.triggerElement}`}
                    style={{
                        color: props.section.color
                            ? props.section.color
                            : 'inherit',
                    }}
                >
                    <span class="font-medium text-sm mb-1">
                        {props.section.title}
                    </span>
                    <div class={styles.triggerElementChevron}>
                        <ChevronUpIcon
                            class="h-4 mr-1 opacity-70 transition-transform"
                            aria-label="chevron"
                            classList={{ 'rotate-180': !expanded() }}
                        />
                    </div>
                </Collapsible.Trigger>
                <Collapsible.Content>
                    <div class="flex flex-col gap-1">
                        <For each={props.section.links}>
                            {(link) => (
                                <div class={styles.element}>
                                    <Tooltip.Root
                                        closeDelay={100}
                                        positioning={{
                                            placement: 'top-start',
                                        }}
                                    >
                                        <Tooltip.Trigger class="flex w-[95%] overflow-hidden">
                                            <Link
                                                link={link}
                                                onOpenSource={(source: {
                                                    id: string;
                                                }) =>
                                                    props.onOpenSource(source)
                                                }
                                            />
                                        </Tooltip.Trigger>
                                        <Tooltip.Positioner>
                                            <Tooltip.Content class="z-10">
                                                <Tooltip.Arrow>
                                                    <Tooltip.ArrowTip />
                                                </Tooltip.Arrow>
                                                {link.title}
                                            </Tooltip.Content>
                                        </Tooltip.Positioner>
                                    </Tooltip.Root>
                                </div>
                            )}
                        </For>
                    </div>
                </Collapsible.Content>
            </Collapsible.Root>
        </Show>
    );
};

export default (props: { class?: string }) => {
    const config = useConfig();

    const [expanded, setExpanded] = createSignal(true);
    const [showedSource, setShowedSource] = createSignal<
        { id: string } | undefined
    >(undefined);

    const staticLinks = config.sidebar.staticLinks;

    return (
        <Show when={staticLinks.some((section) => section.links.length > 0)}>
            <Collapsible.Root
                class={`group ${props.class ? props.class : ''}`}
                onOpenChange={() => setExpanded(true)}
                onExitComplete={() => setExpanded(false)}
                open={expanded()}
            >
                <Collapsible.Trigger class={`group ${styles.trigger}`}>
                    <div
                        class={styles.triggerElement}
                        classList={{ 'font-semibold': expanded() }}
                    >
                        <FlashIcon
                            class={styles.triggerElementWatch}
                            aria-label="direct access"
                        />
                        <Trans key="layout.links.title" />
                        <div class={styles.triggerElementChevron}>
                            <ChevronUpIcon
                                class="h-4 transition-transform"
                                aria-label="chevron"
                                classList={{ 'rotate-180': !expanded() }}
                            />
                        </div>
                    </div>
                </Collapsible.Trigger>
                <Collapsible.Content
                    class={`${styles.collapsible} pl-[0.25rem] flex flex-col gap-1.5`}
                >
                    <For each={expanded() ? staticLinks : []}>
                        {(section) => (
                            <LinkSection
                                section={section}
                                onOpenSource={setShowedSource}
                            />
                        )}
                    </For>
                </Collapsible.Content>
            </Collapsible.Root>
            <Show when={showedSource()}>
                {(source) => (
                    <Suspense>
                        <DocumentSourceModal
                            source={{
                                id: source().id,
                                spans: [{ start: 1 }] as ScoredSpan[],
                                metadata: {
                                    sources: [source().id],
                                    location: 'direct-access',
                                    message: '',
                                },
                            }}
                            onClose={() => {
                                setShowedSource(undefined);
                            }}
                        />
                    </Suspense>
                )}
            </Show>
        </Show>
    );
};
