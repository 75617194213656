import { FeedbackValue } from '@zelros/interfaces-utils';
import { SellingPointDisplay } from './selling-point.display.model';

export interface SellingPointDisplayDTO {
    id: string;
    resource_id: string; // Deprecated, use resource.id instead
    resource: {
        id: string;
        created_at: string;
        updated_at: string;
    };
    title: string;
    illustration: string;
    limitation: string | null;
    topics: string[];
    feedback: {
        value: string | null;
        count_positive: number;
        count_suggestions: number;
    };
    rl: {
        prediction_id: string;
        score: number;
    } | null;
    data: Record<string, any>;
}

export function toSellingPointDisplayDTO(
    display: SellingPointDisplay,
): SellingPointDisplayDTO {
    return {
        id: display.id,
        resource_id: display.sellingPoint.id,
        resource: {
            id: display.sellingPoint.id,
            created_at: display.sellingPoint.createdAt.toISOString(),
            updated_at: display.sellingPoint.updatedAt.toISOString(),
        },
        title: display.title,
        illustration: display.illustration,
        limitation: display.limitation,
        topics: display.sellingPoint.topics,
        feedback: {
            value: display.feedback.value,
            count_positive: display.feedback.countPositive || 0,
            count_suggestions: display.feedback.countSuggestions || 0,
        },
        rl: display.rl
            ? {
                  prediction_id: display.rl.predictionId,
                  score: display.rl.score,
              }
            : null,
        data: display.data,
    };
}

export function sellingPointDisplayFromDTO(
    dto: SellingPointDisplayDTO,
): SellingPointDisplay {
    return {
        id: dto.id,
        sellingPoint: {
            id: dto.resource.id,
            priority: 0,
            topics: [],
            createdAt: new Date(dto.resource.created_at),
            updatedAt: new Date(dto.resource.updated_at),
        },
        title: dto.title,
        illustration: dto.illustration,
        limitation: dto.limitation,
        feedback: {
            value: dto.feedback.value as FeedbackValue | null,
            countPositive: dto.feedback.count_positive,
            countSuggestions: dto.feedback.count_suggestions,
        },
        rl: dto.rl
            ? {
                  predictionId: dto.rl.prediction_id,
                  score: dto.rl.score,
              }
            : null,
        data: dto.data,
    };
}

export interface SellingPointDisplayForActivityDTO {
    id: string;
    resource_id: string;
    title: string;
    feedback: {
        value: string | null;
    };
}

export function toSellingPointDisplayForActivityDTO(
    display: SellingPointDisplay,
): SellingPointDisplayForActivityDTO {
    return {
        id: display.id,
        resource_id: display.sellingPoint.id,
        title: display.title,
        feedback: {
            value: display.feedback.value,
        },
    };
}
