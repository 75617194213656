import { Sort, sortFromDTO } from '@zelros/interfaces-utils';
import { AgentQueryDTO, DEFAULT_AGENT_QUERY_DTO } from './query.dto';

export interface AgentQuery extends Sort {
    sortBy: AgentSortBy;
}

export enum AgentSortBy {
    Name = 'name',
    Type = 'type',
    UpdatedAt = 'updatedAt',
}

export const DEFAULT_AGENT_QUERY = agentQueryFromDTO(DEFAULT_AGENT_QUERY_DTO);

export function agentQueryFromDTO(dto: AgentQueryDTO): AgentQuery {
    return sortFromDTO(dto) as AgentQuery;
}
