import { AssistantMessageUsage, BaseMessage, ToolCall } from '@zelros/threads';
import {
    ExecutableToolResultActionDTO,
    ExecutableToolResultSourceDTO,
    executableToolResultSourceFromDTO,
    toExecutableToolResultSourceDTO,
} from './executable.dto';
import {
    FrameworkAssistantMessageMetadata,
    FrameworkMessageMetadata,
    FrameworkSystemMessageMetadata,
    FrameworkToolMessageMetadata,
    FrameworkUserMessageMetadata,
} from './message.model';

export type FrameworkMessageMetadataDTO = Record<string, unknown>;

export interface FrameworkMessageDTO extends BaseMessage {
    role: 'system' | 'user' | 'tool' | 'assistant';
    metadata: FrameworkMessageMetadataDTO;
}

export interface FrameworkSystemMessageDTO extends FrameworkMessageDTO {
    role: 'system';
    content: string;
}

export type FrameworkSystemMessageMetadataDTO = FrameworkMessageMetadataDTO;

export function toFrameworkSystemMessageMetadataDTO(
    metadata: FrameworkSystemMessageMetadata,
): FrameworkSystemMessageMetadataDTO {
    return metadata; // No problematic property
}

export function frameworkSystemMessageMetadataFromDTO(
    dto: FrameworkSystemMessageMetadataDTO,
): FrameworkSystemMessageMetadata {
    return dto; // No problematic property
}

export interface FrameworkUserMessageDTO extends FrameworkMessageDTO {
    role: 'user';
    content: string;
    metadata: FrameworkUserMessageMetadataDTO;
}

export interface FrameworkUserMessageMetadataDTO
    extends FrameworkMessageMetadataDTO {
    from_sdk?: boolean;
    sdk_event?: {
        topic: string;
        payload?: unknown;
    };
}

export function toFrameworkUserMessageMetadataDTO(
    metadata: FrameworkUserMessageMetadata,
): FrameworkUserMessageMetadataDTO {
    return {
        from_sdk: metadata.fromSDK,
        sdk_event: metadata.sdkEvent,
    };
}

export function frameworkUserMessageMetadataFromDTO(
    dto: FrameworkUserMessageMetadataDTO,
): FrameworkUserMessageMetadata {
    return {
        fromSDK: dto.from_sdk,
        sdkEvent: dto.sdk_event,
    };
}

export interface FrameworkAssistantMessageDTO extends FrameworkMessageDTO {
    role: 'assistant';
    content: string | null;
    tool_calls: ToolCall[];
    usage?: AssistantMessageUsage;
    duration: number;
    metadata: FrameworkAssistantMessageMetadataDTO;
}

export interface FrameworkAssistantMessageMetadataDTO
    extends FrameworkMessageMetadata {
    error?: unknown;
    from_workflow?: boolean;

    /**
     * If set (can be a string or null), the frontend will override the content of the message with this value.
     */
    override_content?: string | null;
}

export function toFrameworkAssistantMessageMetadataDTO(
    metadata: FrameworkAssistantMessageMetadata,
): FrameworkAssistantMessageMetadataDTO {
    return {
        error: metadata.error,
        from_workflow: metadata.fromWorkflow,
        override_content: metadata.overrideContent,
    };
}

export function frameworkAssistantMessageMetadataFromDTO(
    dto: FrameworkAssistantMessageMetadataDTO,
): FrameworkAssistantMessageMetadata {
    return {
        error: dto.error,
        fromWorkflow: dto.from_workflow,
        overrideContent: dto.override_content,
    };
}

export interface FrameworkToolMessageDTO extends FrameworkMessageDTO {
    role: 'tool';
    content: string;
    tool_call_id: string;
    metadata: FrameworkToolMessageMetadataDTO;
}

export interface FrameworkToolMessageMetadataDTO
    extends FrameworkMessageMetadataDTO {
    value: any;
    name: string;
    keep_in_context: boolean;
    sources: ExecutableToolResultSourceDTO[];
    shortened_links?: Record<string, string>;
    error?: string;
    actions: ExecutableToolResultActionDTO[];
}

export function toFrameworkToolMessageMetadataDTO(
    metadata: FrameworkToolMessageMetadata,
): FrameworkToolMessageMetadataDTO {
    return {
        value: metadata.value,
        name: metadata.name,
        keep_in_context: metadata.keepInContext,
        sources: metadata.sources
            ? metadata.sources.map(toExecutableToolResultSourceDTO)
            : [],
        shortened_links: metadata.shortenedLinks
            ? Object.fromEntries(metadata.shortenedLinks.entries())
            : undefined,
        error: metadata.error,
        actions: metadata.actions,
    };
}

export function frameworkToolMessageMetadataFromDTO(
    dto: FrameworkToolMessageMetadataDTO,
): FrameworkToolMessageMetadata {
    return {
        value: dto.value,
        name: dto.name,
        keepInContext: dto.keep_in_context,
        sources: dto.sources
            ? dto.sources.map(executableToolResultSourceFromDTO)
            : [],
        shortenedLinks: dto.shortened_links
            ? new Map(Object.entries(dto.shortened_links))
            : undefined,
        error: dto.error,
        actions: dto.actions,
    };
}
