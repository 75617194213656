/**
 * As per MDN https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream#specifications
 * ReadableStream is not available in any browser but firefox yet, so we need to polyfill it.
 *
 * Polyfill from https://bugs.chromium.org/p/chromium/issues/detail?id=929585
 */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
ReadableStream.prototype[Symbol.asyncIterator] = async function* () {
    const reader = this.getReader();
    try {
        while (true) {
            const { done, value } = await reader.read();
            if (done) return;
            yield value;
        }
    } finally {
        reader.releaseLock();
    }
};
