import ChevronLeftDoubleIcon from '@icons/arrows/chevron-left-double.svg';
import ChevronRightDoubleIcon from '@icons/arrows/chevron-right-double.svg';
import MessageXSquare02Icon from '@icons/communication/message-x-square-02.svg';
import { Trans } from '@mbarzda/solid-i18next';
import { A } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import { Motion } from 'solid-motionone';
import StaticLinks from '~/layout/StaticLinks';
import RecentThreads from '../thread/recent-threads/RecentThreads';

export default function Sidebar() {
    const [expanded, setExpanded] = createSignal(true);

    const chevronLeft = (
        <ChevronLeftDoubleIcon
            class="h-[24px] w-[24px]"
            aria-label="chevron left double"
        />
    );

    const chevronRight = (
        <ChevronRightDoubleIcon
            class="h-[24px] w-[24px]"
            aria-label="chevron right double"
        />
    );

    const toggleButton = (
        <button
            type="button"
            onClick={() => setExpanded((prev) => !prev)}
            aria-label="toggle sidebar"
            class="text-indigo-200 hover:text-indigo-600 self-end"
        >
            <Show when={expanded()} fallback={chevronRight}>
                {chevronLeft}
            </Show>
        </button>
    );

    return (
        <Motion.aside
            animate={{
                width: expanded()
                    ? 'var(--sidebar-width)'
                    : 'var(--closed-sidebar-width)',
            }}
            transition={{ duration: 0.2 }}
            initial={false}
            class="flex flex-col p-2 h-screen gap-5 bg-white border-r border-indigo-50"
        >
            {toggleButton}
            <A
                href=""
                class="text-main-600 bg-main-50 enabled:hover:bg-main-100 active:text-white active:bg-main-600 rounded-lg p-2 flex gap-2 w-full justify-center items-center group font-semibold"
            >
                <MessageXSquare02Icon
                    class="w-[22px] h-[22px]"
                    aria-label="chat"
                />
                <Show when={expanded()}>
                    <Trans key="layout.conversations.new" />
                </Show>
            </A>
            <Show when={expanded()}>
                <RecentThreads />
                <StaticLinks class="mt-12" />
            </Show>
        </Motion.aside>
    );
}
