import {
    type OpportunityDTO,
    opportunityFromDTO,
} from '@zelros/recommendations-interfaces';
import {
    isThreadToolMessage,
    type ThreadToolMessage,
} from '@zelros/standalone-interfaces';
import { get as levenshtein } from 'fast-levenshtein';
import { isEqual } from 'lodash-es';
import {
    type Accessor,
    batch,
    createContext,
    createEffect,
    createSignal,
    type JSX,
    on,
    untrack,
    useContext,
} from 'solid-js';
import { useConfig } from '~/config/ConfigProvider';
import { useLogging } from '~/logging/LoggingContext';
import { useThread } from '../../ThreadContext';
import {
    type OpportunityDisplay,
    type RecommendationDisplay,
    toOpportunityDisplay,
} from './opportunities.model';

const GET_CUSTOMER_RECOMMENDATIONS_TOOL_NAME = 'get_customer_recommendations';

type OpportunitiesContextType = {
    getOpportunity: (id: string) => OpportunityDisplay;
    getRecommendation: (id: string) => RecommendationDisplay;
    latestOpportunities: Accessor<OpportunityDisplay[]>;
    opportunitiesFetched: Accessor<boolean>;
};

const OpportunitiesContext = createContext<OpportunitiesContextType>();

interface OpportunitiesProviderProps {
    children: JSX.Element;
}

export const OpportunitiesProvider = (props: OpportunitiesProviderProps) => {
    const { log } = useLogging();
    const config = useConfig();
    //const productsConfig = useProductsConfig();
    const { threadMessages } = useThread();

    const products = config.recommendation.lookAndFeel.products;
    // Convert to a map for easier access
    const productsMap = new Map(
        products.map((product) => [product.id, product]),
    );

    const [opportunities, setOpportunities] = createSignal<
        OpportunityDisplay[]
    >([]);
    const [latestOpportunities, setLatestOpportunities] = createSignal<
        OpportunityDisplay[]
    >([]);
    const [opportunitiesFetched, setOpportunitiesFetched] = createSignal(false);

    const handleRecommendations = (
        recommendationMessages: ThreadToolMessage[],
    ) => {
        /**
         * Build list of opportunities from the recommendations messages
         * We filter out the duplicates, keeping only the last message for each opportunity
         */
        const opportunitiesFromMessages = recommendationMessages
            .flatMap((el: ThreadToolMessage) =>
                (el.metadata.value as OpportunityDTO[]).map((opportunity) =>
                    toOpportunityDisplay(
                        opportunityFromDTO(opportunity),
                        productsMap,
                    ),
                ),
            )
            .filter((opportunity, index, self) => {
                // findLastIndex is not available for ES2022
                const lastIndex = self.reduce(
                    (lastIndex, current, i) =>
                        current.id === opportunity.id ? i : lastIndex,
                    -1,
                );
                return index === lastIndex;
            });

        // Only update the opportunities if they have changed
        const opps = untrack(opportunities);
        if (!isEqual(opps, opportunitiesFromMessages)) {
            log('OpportunitiesContext: Updating opportunities...');
            setOpportunities(opportunitiesFromMessages);
        }

        /**
         * Update the latestOpportunities with the last message opportunities,
         * if they are not already in the latestOpportunities
         */
        if (recommendationMessages.length > 0) {
            const lastMessageOpportunities = recommendationMessages[
                recommendationMessages.length - 1
            ].metadata.value as OpportunityDTO[];

            // Only update the latestOpportunities if they have changed
            const latestOpps = untrack(latestOpportunities);
            if (!isEqual(latestOpps, lastMessageOpportunities)) {
                log('OpportunitiesContext: Updating latest opportunities...');
                setLatestOpportunities(
                    lastMessageOpportunities
                        .map(opportunityFromDTO)
                        .map((opportunity) =>
                            toOpportunityDisplay(opportunity, productsMap),
                        ),
                );
            }
        }
    };

    createEffect(
        on(threadMessages, () => {
            const recommendationMessages = threadMessages().filter(
                (message) =>
                    isThreadToolMessage(message) &&
                    message.metadata.name ===
                        GET_CUSTOMER_RECOMMENDATIONS_TOOL_NAME,
            );

            if (recommendationMessages.length > 0) {
                setOpportunitiesFetched(true);
            } else {
                batch(() => {
                    setOpportunitiesFetched(false);
                    setLatestOpportunities([]);
                });
            }
            handleRecommendations(
                recommendationMessages as ThreadToolMessage[],
            );
        }),
    );

    const getRecommendation = (id: string): RecommendationDisplay => {
        const opps = untrack(opportunities);

        for (const opp of opps) {
            /**
             * Sometimes the LLM confuses the ID of the recommendation with the ID of the opportunity
             * So we try to find an opportunity with a similar ID and if found, use the first recommendation of this opportunity
             */
            if (levenshtein(opp.id, id) < 3) {
                return opp.recommendations[0];
            }

            for (const recommendation of opp.recommendations) {
                if (levenshtein(recommendation.id, id) < 3) {
                    return recommendation;
                }
            }
        }

        throw new Error(`Recommendation ${id} not found`);
    };

    const getOpportunity = (id: string): OpportunityDisplay => {
        const opps = untrack(opportunities);

        const opportunity = opps.find((opp) => levenshtein(opp.id, id) < 3);

        if (!opportunity) {
            throw new Error(`Opportunity ${id} not found`);
        }

        return opportunity;
    };

    return (
        <OpportunitiesContext.Provider
            value={{
                getOpportunity,
                getRecommendation,
                latestOpportunities,
                opportunitiesFetched,
            }}
        >
            {props.children}
        </OpportunitiesContext.Provider>
    );
};

export const useOpportunities = () => {
    const context = useContext(OpportunitiesContext);
    if (!context) {
        throw new Error(
            'useOpportunities must be used within a OpportunitiesProvider',
        );
    }
    return context;
};
