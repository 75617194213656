import { WarningDisplay, WarningFeedback } from './warning-display.model';
import { WarningType } from './warning.model';

export interface WarningDisplayDTO {
    id: string;
    resource_id: string; // Deprecated, use resource.id instead
    resource: {
        id: string;
        created_at: string;
        updated_at: string;
    };
    type: string;
    name: string;
    message: string;
    description: string | null;
    feedback: { value: WarningFeedback } | null;
    image: string | null;
    data: Record<string, any>;
}

export interface ISaveWarningDisplayFeedbackDTO {
    feedback: WarningFeedback;
}

export function toWarningDisplayDTO(
    display: WarningDisplay,
): WarningDisplayDTO {
    return {
        id: display.id,
        resource_id: display.warning.id,
        resource: {
            id: display.warning.id,
            created_at: display.warning.createdAt.toISOString(),
            updated_at: display.warning.updatedAt.toISOString(),
        },
        type: display.type,
        name: display.name,
        message: display.message,
        description: display.description,
        feedback: display.feedback || null,
        image: display.image,
        data: display.data,
    };
}

export function warningDisplayFromDTO(
    warning: WarningDisplayDTO,
): WarningDisplay {
    return {
        id: warning.id,
        warning: {
            id: warning.resource.id,
            createdAt: new Date(warning.resource.created_at),
            updatedAt: new Date(warning.resource.updated_at),
        },
        name: warning.name,
        message: warning.message,
        description: warning.description,
        feedback: warning.feedback || null,
        type: warning.type as WarningType,
        image: warning.image,
        data: warning.data,
    };
}

export interface WarningDisplayForActivityDTO {
    id: string;
    resource_id: string;
    type: string;
    name: string;
    feedback: { value: WarningFeedback } | null;
}

export function toWarningDisplayForActivityDTO(
    display: WarningDisplay,
): WarningDisplayForActivityDTO {
    return {
        id: display.id,
        resource_id: display.warning.id,
        type: display.type,
        name: display.name,
        feedback: display.feedback || null,
    };
}
