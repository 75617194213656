import { Sort, sortFromDTO } from '@zelros/interfaces-utils';
import { DEFAULT_SCRIPT_QUERY_DTO, ScriptQueryDTO } from './script.dto';

export interface ScriptQuery extends Sort {
    sortBy: ScriptSortBy | null;
}

export enum ScriptSortBy {
    Name = 'name',
    UpdatedAt = 'updatedAt',
}

export const DEFAULT_SCRIPT_QUERY = scriptQueryFromDTO(
    DEFAULT_SCRIPT_QUERY_DTO,
);

export function scriptQueryFromDTO(dto: ScriptQueryDTO): ScriptQuery {
    return sortFromDTO(dto) as ScriptQuery;
}
