import {
    Opportunity,
    OpportunityDTO,
    opportunityFromDTO,
} from '@zelros/recommendations-interfaces';
import {
    isThreadToolMessage,
    type ThreadToolMessage,
} from '@zelros/standalone-interfaces';
import {
    Accessor,
    createContext,
    createEffect,
    createSignal,
    untrack,
    useContext,
    type JSX,
} from 'solid-js';
import * as _ from 'lodash-es';
import { useLogging } from '~/logging/LoggingContext';
import { useMessaging } from '~/messaging/MessagingContext';
import { useThread } from '~/thread/ThreadContext';

interface CustomerContextType {
    currentExternalId: Accessor<string | undefined>;
    setCurrentExternalId: (externalId: string | undefined) => void;
}

const CustomerStateContext = createContext<CustomerContextType>();

const GET_CUSTOMER_RECOMMENDATIONS_TOOL_NAME = 'get_customer_recommendations';

export const CustomerStateProvider = (props: { children: JSX.Element }) => {
    const { log } = useLogging();
    const { threadMessages } = useThread();
    const { send } = useMessaging();

    const [state, setState] = createSignal<CustomerState>(EMPTY_CUSTOMER_STATE);
    const [externalId, setExternalId] = createSignal<string>();

    createEffect(() => {
        log('CustomerStateContext: Running CustomerStateProvider effect...');
        const messages = threadMessages();

        const prevState = untrack(state);

        /**
         * Find the most recent customer-related "tool" message
         */
        let customerToolMessage: ThreadToolMessage | null = null;
        let customerToolMessageIndex = -1;
        for (let i = messages.length - 1; i >= 0; i--) {
            const message = messages[i];

            if (
                isThreadToolMessage(message) &&
                CUSTOMER_TOOLS.includes(message.metadata.name)
            ) {
                customerToolMessage = message;
                customerToolMessageIndex = i;
                break;
            }
        }
        if (customerToolMessage) {
            const customer = customerToolMessage.metadata
                .value as CustomerStateCustomer;

            setExternalId(customer.externalId);

            /**
             * Retrieve the latest opportunities by searching for the last "get_customer_recommendations" message
             * between the customer tool message and the end of the messages array
             */
            let opportunitiesToolMessage: ThreadToolMessage | null = null;
            for (
                let i = customerToolMessageIndex + 1;
                i < messages.length;
                i++
            ) {
                const message = messages[i];

                if (
                    isThreadToolMessage(message) &&
                    message.metadata.name ===
                        GET_CUSTOMER_RECOMMENDATIONS_TOOL_NAME
                ) {
                    opportunitiesToolMessage = message;
                    break;
                }
            }

            let opportunities = prevState.opportunities;
            let recommendations = prevState.recommendations;

            if (opportunitiesToolMessage) {
                opportunities = (
                    opportunitiesToolMessage.metadata.value as OpportunityDTO[]
                ).map(opportunityFromDTO);
                recommendations =
                    convertOpportunitiesToLegacyRecommendations(opportunities);
            }

            const newState: CustomerState = {
                customer,
                opportunities,
                recommendations,
            };

            if (!_.isEqual(prevState, newState)) {
                setState(newState);
            }
        } else {
            setState(EMPTY_CUSTOMER_STATE);
        }
    });

    createEffect((prevState: CustomerState | undefined) => {
        const s = state();

        send('state_change', {
            previousState: prevState ?? null,
            currentState: s,
        });

        return s;
    });

    return (
        <CustomerStateContext.Provider
            value={{
                currentExternalId: externalId,
                setCurrentExternalId: setExternalId,
            }}
        >
            {props.children}
        </CustomerStateContext.Provider>
    );
};

interface CustomerState {
    customer: CustomerStateCustomer | null;
    opportunities: Opportunity[];
    /**
     * @deprecated
     */
    recommendations: RecommendationLegacy[]; // For retrocompatibility
}

interface CustomerStateCustomer {
    id: string;
    externalId: string;
    hash: string;
}

const EMPTY_CUSTOMER_STATE: CustomerState = {
    customer: null,
    opportunities: [],
    recommendations: [],
};

const CUSTOMER_TOOLS = [
    'get_customer_profile',
    'create_customer',
    'update_customer_profile',
];

export interface RecommendationLegacy {
    id: string;
    recommendedProductId: string;

    contextualInfo: {
        id: string;
        label: string;
        additionalInfo: string | null;
        data: any;
    };

    product: {
        id: string;
        externalId: string;
        name: string;
        comparable: boolean;
        decisionDisplay: boolean;
        meetingDisplay: boolean;
        goQuoteDisplay: boolean;
        rejectQuoteDisplay: boolean;
        decisionSelectDisplay: boolean;
        sellingPointFeedbackSelectDisplay: boolean;
        scoreDisplay: boolean;
    };

    questions: any[];
    sellingPoints: {
        id: string;
        illustration: string;
        label: string;
        feedback: string | null;
        recognizedSentence: string | null;
        limitation: string | null;
        predictionId: string | null;
    }[];

    percentage: number | null;

    currentQuote: false;
}

function convertOpportunitiesToLegacyRecommendations(
    opportunities: Opportunity[],
): RecommendationLegacy[] {
    const recommendations: RecommendationLegacy[] = [];
    for (const o of opportunities) {
        recommendations.push(...convertOpportunityToLegacyRecommendations(o));
    }
    return recommendations;
}

function convertOpportunityToLegacyRecommendations(
    opportunity: Opportunity,
): RecommendationLegacy[] {
    return opportunity.recommendations.map((recommendation) => {
        return {
            id: recommendation.id,
            recommendedProductId: 'CD112996-E445-4615-8FCD-0BBA20BDBC86', // Not used anymore
            product: {
                id: recommendation.product.id,
                externalId: recommendation.product.externalId,
                name: recommendation.product.name,
                comparable: false,
                decisionDisplay: true,
                meetingDisplay: false,
                goQuoteDisplay: true,
                rejectQuoteDisplay: true,
                decisionSelectDisplay: true,
                sellingPointFeedbackSelectDisplay: true,
                scoreDisplay: true,
            },
            percentage: recommendation.propensity?.percentage,
            currentQuote: false,
            contextualInfo: {
                id: opportunity.lifeEventDisplay.lifeEvent.id,
                label: opportunity.lifeEventDisplay.title,
                additionalInfo:
                    opportunity.lifeEventDisplay.lifeEvent.productSettings.find(
                        ({ id }) => id === recommendation.product.id,
                    )?.catchPhrase,
                data: {},
            },
            sellingPoints: recommendation.sellingPointDisplays.map((sp) => ({
                id: sp.id,
                illustration: sp.illustration,
                label: sp.title,
                limitation: sp.limitation,
                feedback: sp.feedback ? sp.feedback.value : null,
                prediction_id: sp.rl ? sp.rl.predictionId : null,
                recognizedSentence: null,
                predictionId: null,
            })),
            decision: recommendation.decision
                ? {
                      id: recommendation.decision.id,
                      value: recommendation.decision.value,
                      createdAt: recommendation.decision.createdAt,
                      updatedAt: recommendation.decision.updatedAt,
                      createdBy: recommendation.decision.createdBy.id,
                  }
                : null,
            questions: [],
        };
    }) as RecommendationLegacy[];
}

export const useCustomerState = () => {
    const context = useContext(CustomerStateContext);
    if (!context) {
        throw new Error(
            'useCustomerState must be used within a CustomerStateProvider',
        );
    }
    return context;
};
