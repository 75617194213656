import { New } from '@zelros/interfaces-utils';
import { ToolDefinition } from '@zelros/threads';
import {
    getDefaultArgumentsBuilder,
    getDefaultCondition,
    getDefaultContentBuilder,
    getDefaultOverrideContentBuilder,
    IHook,
    IHookStep,
    ScriptArgument,
} from './base.hook.model';

export interface IPostToolMessageHook extends IHook {
    /**
     * Name of the tools that can potentially (cf also condition) trigger the hook
     */
    triggers: string[];
}

export interface IAgentPostToolMessageHook extends IPostToolMessageHook {
    /**
     * ID of the hook in DB
     */
    id: string;
}

export type BuiltinAgentPostToolMessageHook = IPostToolMessageHook;

export const POST_TOOL_INITIAL_SCRIPT_ARGS: ScriptArgument[] = [
    {
        name: 'initialAssistantMessage',
        jsdoc: "{{role: 'assistant', content: string, metadata: object}} initialAssistantMessage The initial assistant message in which the LLM requested the tool call that triggered this hook",
    },
    {
        name: 'triggeringToolMessage',
        jsdoc: "{{role: 'tool', content: string, metadata: object}} triggeringToolMessage The tool message that triggered this hook",
    },
    {
        name: 'otherToolMessages',
        jsdoc: "{{role: 'tool', content: string, metadata: object}[]} otherToolMessages The other tool messages corresponding to the other tool calls that the LLM may have requested in the same assistant message",
    },
];

export const POST_TOOL_SUBSEQUENT_SCRIPT_ARGS: ScriptArgument[] = [
    ...POST_TOOL_INITIAL_SCRIPT_ARGS,
    {
        name: 'currentMessages',
        jsdoc: "{{role: 'assistant' | 'tool', content: string | null, metadata: object}[]} currentMessages The current messages produced by this hook",
    },
];

export const DEFAULT_POST_TOOL_MESSAGE_HOOK_CONDITION = getDefaultCondition(
    POST_TOOL_INITIAL_SCRIPT_ARGS,
);

export const DEFAULT_POST_TOOL_MESSAGE_HOOK_STEP_CONDITION =
    getDefaultCondition(POST_TOOL_SUBSEQUENT_SCRIPT_ARGS);

export const DEFAULT_POST_TOOL_MESSAGE_HOOK_STEP_CONTENT_BUILDER =
    getDefaultContentBuilder(POST_TOOL_SUBSEQUENT_SCRIPT_ARGS);

export const DEFAULT_POST_TOOL_MESSAGE_HOOK_STEP_OVERRIDE_CONTENT_BUILDER =
    getDefaultOverrideContentBuilder(POST_TOOL_SUBSEQUENT_SCRIPT_ARGS);

export const DEFAULT_POST_TOOL_MESSAGE_HOOK_STEP: IHookStep = {
    name: 'My step',
    condition: DEFAULT_POST_TOOL_MESSAGE_HOOK_STEP_CONDITION,
    contentBuilder: DEFAULT_POST_TOOL_MESSAGE_HOOK_STEP_CONTENT_BUILDER,
    overrideContentBuilder: null,
};

export const DEFAULT_POST_TOOL_MESSAGE_HOOK: New<IAgentPostToolMessageHook> = {
    name: 'My post-tool-message hook',
    condition: DEFAULT_POST_TOOL_MESSAGE_HOOK_CONDITION,
    triggers: [],
    steps: [DEFAULT_POST_TOOL_MESSAGE_HOOK_STEP],
};

export function getDefaultPostToolMessageHookStepToolCallArgsBuilder(
    definition: ToolDefinition,
): string {
    return getDefaultArgumentsBuilder(
        POST_TOOL_SUBSEQUENT_SCRIPT_ARGS,
        definition,
    );
}
