import type { RouteSectionProps } from '@solidjs/router';
import { Show } from 'solid-js';
import { isLarge } from '~/Constants';
import Sidebar from '~/layout/Sidebar';

export const Layout = (props: RouteSectionProps<unknown>) => {
    return (
        <>
            <Show when={isLarge()}>
                <Sidebar />
            </Show>
            <main id="main">{props.children}</main>
            <div id="modal-root" />
        </>
    );
};
