import { StandardizedPropensity } from './propensity.model';

export interface StandardizedPropensityDTO {
    product: {
        id: string;
        /*
         * @deprecated, kept for backward compatibility with the SalesForce integration
         */
        catalog_id: string;
    };
    prediction: {
        score: number;
        explainability: {
            positive_features: {
                position: number;
                feature: string;
                value: number;
                impact: number;
                label: string;
            }[];
            negative_features: {
                position: number;
                feature: string;
                value: number;
                impact: number;
                label: string;
            }[];
        };
    } | null;
    percentage: number | null;
}

export interface PropensityForActivityDTO {
    product: {
        id: string;
    };
}

export function toStandardizedPropensityDTO(
    propensity: StandardizedPropensity,
): StandardizedPropensityDTO {
    return {
        product: {
            id: propensity.product.id,
            catalog_id: propensity.product.id,
        },
        prediction: propensity.prediction
            ? {
                  score: propensity.prediction.score,
                  explainability: {
                      positive_features:
                          propensity.prediction.explainability.positiveFeatures,
                      negative_features:
                          propensity.prediction.explainability.negativeFeatures,
                  },
              }
            : null,
        percentage: propensity.percentage,
    };
}

export function standardizedPropensityFromDTO(
    dto: StandardizedPropensityDTO,
): StandardizedPropensity {
    return {
        product: {
            id: dto.product.id,
        },
        prediction: dto.prediction
            ? {
                  score: dto.prediction.score,
                  explainability: {
                      positiveFeatures:
                          dto.prediction.explainability.positive_features,
                      negativeFeatures:
                          dto.prediction.explainability.negative_features,
                  },
              }
            : null,
        percentage: dto.percentage,
    };
}

export function toPropensityForActivityDTO(
    propensity: StandardizedPropensity,
): PropensityForActivityDTO {
    return {
        product: {
            id: propensity.product.id,
        },
    };
}
