import { FeedbackValue } from '@zelros/interfaces-utils';
import {
    AssistantMessage,
    BaseMessage,
    SystemMessage,
    ToolMessage,
    UserMessage,
} from '@zelros/threads';
import {
    FrameworkAssistantMessageMetadataDTO,
    frameworkAssistantMessageMetadataFromDTO,
    FrameworkMessageMetadataDTO,
    frameworkSystemMessageMetadataFromDTO,
    FrameworkToolMessageMetadataDTO,
    frameworkToolMessageMetadataFromDTO,
    FrameworkUserMessageMetadataDTO,
    frameworkUserMessageMetadataFromDTO,
    toFrameworkAssistantMessageMetadataDTO,
    toFrameworkSystemMessageMetadataDTO,
    toFrameworkToolMessageMetadataDTO,
    toFrameworkUserMessageMetadataDTO,
} from '../framework';
import {
    isThreadAssistantMessage,
    isThreadSystemMessage,
    isThreadToolMessage,
    isThreadUserMessage,
    IThread,
    IThreadAgent,
    IThreadMessage,
    ThreadAssistantMessage,
    ThreadSystemMessage,
    ThreadToolMessage,
    ThreadUserMessage,
} from './thread.model';

export interface ThreadDTO {
    id: string;
    agent: ThreadAgentDTO;
    messages: ThreadMessageDTO[];
    created_at: string;
    updated_at: string;
}

export interface ThreadAgentDTO {
    id: string;
    name: string;
}

export interface ThreadMessageFeedbackDTO {
    value: FeedbackValue;
    comment: string | null;
    reason: string | null;
}

export type ThreadMessageDTO =
    | ThreadSystemMessageDTO
    | ThreadUserMessageDTO
    | ThreadToolMessageDTO
    | ThreadAssistantMessageDTO;

export interface BaseThreadMessageDTO {
    id: string;
    message: BaseMessage;
    metadata: FrameworkMessageMetadataDTO;
    created_at: string;
    updated_at: string;
}

export interface ThreadSystemMessageDTO extends BaseThreadMessageDTO {
    message: SystemMessage;
}

function isThreadSystemMessageDTO(
    dto: ThreadMessageDTO,
): dto is ThreadSystemMessageDTO {
    return dto.message.role === 'system';
}

export interface ThreadUserMessageDTO extends BaseThreadMessageDTO {
    message: UserMessage;
    metadata: FrameworkUserMessageMetadataDTO;
}

function isThreadUserMessageDTO(
    dto: ThreadMessageDTO,
): dto is ThreadUserMessageDTO {
    return dto.message.role === 'user';
}

export interface ThreadToolMessageDTO extends BaseThreadMessageDTO {
    message: ToolMessage;
    metadata: FrameworkToolMessageMetadataDTO;
}

function isThreadToolMessageDTO(
    dto: ThreadMessageDTO,
): dto is ThreadToolMessageDTO {
    return dto.message.role === 'tool';
}

export interface ThreadAssistantMessageDTO extends BaseThreadMessageDTO {
    message: AssistantMessage;
    metadata: FrameworkAssistantMessageMetadataDTO;
    feedback: ThreadMessageFeedbackDTO | null;
}

function isThreadAssistantMessageDTO(
    dto: ThreadMessageDTO,
): dto is ThreadAssistantMessageDTO {
    return dto.message.role === 'assistant';
}

export function toThreadDTO(thread: IThread): ThreadDTO {
    return {
        id: thread.id,
        agent: toThreadAgentDTO(thread.agent),
        messages: thread.messages.map(toThreadMessageDTO),
        created_at: thread.createdAt.toISOString(),
        updated_at: thread.updatedAt.toISOString(),
    };
}

/**
 * Remove system message
 * @param thread
 */
export function toThreadPublicDTO(thread: IThread): ThreadDTO {
    return {
        id: thread.id,
        agent: toThreadAgentDTO(thread.agent),
        messages: thread.messages
            .filter((message) => !isThreadSystemMessage(message))
            .map(toThreadMessageDTO),
        created_at: thread.createdAt.toISOString(),
        updated_at: thread.updatedAt.toISOString(),
    };
}

export function toThreadAgentDTO(agent: IThreadAgent): ThreadAgentDTO {
    return {
        id: agent.id,
        name: agent.name,
    };
}

export function toThreadMessageDTO(message: IThreadMessage): ThreadMessageDTO {
    if (isThreadSystemMessage(message)) {
        return toThreadSystemMessageDTO(message);
    } else if (isThreadUserMessage(message)) {
        return toThreadUserMessageDTO(message);
    } else if (isThreadToolMessage(message)) {
        return toThreadToolMessageDTO(message);
    } else if (isThreadAssistantMessage(message)) {
        return toThreadAssistantMessageDTO(message);
    } else {
        throw new Error('Unknown message type');
    }
}

/**
 * Will probably never be used (system messages aren't forwarded to the frontend) but we need it
 * for completeness
 * @param message
 */
function toThreadSystemMessageDTO(
    message: ThreadSystemMessage,
): ThreadSystemMessageDTO {
    return {
        id: message.id,
        message: message.message,
        metadata: toFrameworkSystemMessageMetadataDTO(message.metadata),
        created_at: message.createdAt.toISOString(),
        updated_at: message.updatedAt.toISOString(),
    };
}

function toThreadUserMessageDTO(
    message: ThreadUserMessage,
): ThreadUserMessageDTO {
    return {
        id: message.id,
        message: message.message,
        metadata: toFrameworkUserMessageMetadataDTO(message.metadata),
        created_at: message.createdAt.toISOString(),
        updated_at: message.updatedAt.toISOString(),
    };
}

function toThreadToolMessageDTO(
    message: ThreadToolMessage,
): ThreadToolMessageDTO {
    return {
        id: message.id,
        message: message.message,
        metadata: toFrameworkToolMessageMetadataDTO(message.metadata),
        created_at: message.createdAt.toISOString(),
        updated_at: message.updatedAt.toISOString(),
    };
}

function toThreadAssistantMessageDTO(
    message: ThreadAssistantMessage,
): ThreadAssistantMessageDTO {
    return {
        id: message.id,
        message: message.message,
        feedback: message.feedback,
        metadata: toFrameworkAssistantMessageMetadataDTO(message.metadata),
        created_at: message.createdAt.toISOString(),
        updated_at: message.updatedAt.toISOString(),
    };
}

export function threadFromDTO(dto: ThreadDTO): IThread {
    return {
        id: dto.id,
        agent: threadAgentFromDTO(dto.agent),
        messages: dto.messages.map(threadMessageFromDTO),
        createdAt: new Date(dto.created_at),
        updatedAt: new Date(dto.updated_at),
    };
}

export function threadAgentFromDTO(dto: ThreadAgentDTO): IThreadAgent {
    return {
        id: dto.id,
        name: dto.name,
    };
}

export function threadMessageFromDTO(dto: ThreadMessageDTO): IThreadMessage {
    if (isThreadSystemMessageDTO(dto)) {
        return threadSystemMessageFromDTO(dto);
    } else if (isThreadUserMessageDTO(dto)) {
        return threadUserMessageFromDTO(dto);
    } else if (isThreadToolMessageDTO(dto)) {
        return threadToolMessageFromDTO(dto);
    } else if (isThreadAssistantMessageDTO(dto)) {
        return threadAssistantMessageFromDTO(dto);
    } else {
        throw new Error('Unknown message type');
    }
}

function threadSystemMessageFromDTO(
    dto: ThreadSystemMessageDTO,
): ThreadSystemMessage {
    return {
        id: dto.id,
        message: dto.message,
        metadata: frameworkSystemMessageMetadataFromDTO(dto.metadata),
        createdAt: new Date(dto.created_at),
        updatedAt: new Date(dto.updated_at),
    };
}

function threadUserMessageFromDTO(
    dto: ThreadUserMessageDTO,
): ThreadUserMessage {
    return {
        id: dto.id,
        message: dto.message,
        metadata: frameworkUserMessageMetadataFromDTO(dto.metadata),
        createdAt: new Date(dto.created_at),
        updatedAt: new Date(dto.updated_at),
    };
}

function threadToolMessageFromDTO(
    dto: ThreadToolMessageDTO,
): ThreadToolMessage {
    return {
        id: dto.id,
        message: dto.message,
        metadata: frameworkToolMessageMetadataFromDTO(dto.metadata),
        createdAt: new Date(dto.created_at),
        updatedAt: new Date(dto.updated_at),
    };
}

function threadAssistantMessageFromDTO(
    dto: ThreadAssistantMessageDTO,
): ThreadAssistantMessage {
    return {
        id: dto.id,
        message: dto.message,
        feedback: dto.feedback,
        metadata: frameworkAssistantMessageMetadataFromDTO(dto.metadata),
        createdAt: new Date(dto.created_at),
        updatedAt: new Date(dto.updated_at),
    };
}

export interface SubscribeToThreadDTO {
    agentId?: string;
    threadId: string;
}
