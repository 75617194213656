import { createContext, JSX, useContext } from 'solid-js';
import { noop } from 'lodash-es';
import { useBootstrapConfig } from '~/config/BootstrapConfigProvider';

interface LoggingContextType {
    log: (...args: unknown[]) => void;
}

const LoggingContext = createContext<LoggingContextType>();

export const LoggingProvider = (props: { children: JSX.Element }) => {
    const config = useBootstrapConfig();

    const enabled = config.logging.enabled;

    const log = enabled ? console.log : noop;

    return (
        <LoggingContext.Provider value={{ log }}>
            {props.children}
        </LoggingContext.Provider>
    );
};

export const useLogging = () => {
    const context = useContext(LoggingContext);
    if (!context) {
        throw new Error('useLogging must be used within a LoggingProvider');
    }
    return context;
};
