import { FeedbackValue } from '@zelros/interfaces-utils';
import {
    AssistantMessage,
    BaseMessage,
    SystemMessage,
    ToolMessage,
    UserMessage,
} from '@zelros/threads';
import {
    FrameworkAssistantMessageMetadata,
    FrameworkMessage,
    FrameworkMessageMetadata,
    FrameworkToolMessageMetadata,
    FrameworkUserMessageMetadata,
} from '../framework';

export interface IThread {
    id: string;
    agent: IThreadAgent;
    messages: IThreadMessage[];
    createdAt: Date;
    updatedAt: Date;
}

export interface IThreadAgent {
    id: string;
    name: string;
}

export interface IThreadMessageFeedback {
    value: FeedbackValue;
    reason: string | null;
    comment: string | null;
}

export type IThreadMessage =
    | ThreadSystemMessage
    | ThreadUserMessage
    | ThreadToolMessage
    | ThreadAssistantMessage;

export interface BaseThreadMessage {
    id: string;
    message: BaseMessage;
    metadata: FrameworkMessageMetadata;
    createdAt: Date;
    updatedAt: Date;
}

export interface ThreadSystemMessage extends BaseThreadMessage {
    message: SystemMessage;
}

export function isThreadSystemMessage(
    m: IThreadMessage,
): m is ThreadSystemMessage {
    return m.message.role === 'system';
}

export interface ThreadUserMessage extends BaseThreadMessage {
    message: UserMessage;
    metadata: FrameworkUserMessageMetadata;
}

export function isThreadUserMessage(m: IThreadMessage): m is ThreadUserMessage {
    return m.message.role === 'user';
}

export interface ThreadToolMessage extends BaseThreadMessage {
    message: ToolMessage;
    metadata: FrameworkToolMessageMetadata;
}

export function isThreadToolMessage(m: IThreadMessage): m is ThreadToolMessage {
    return m.message.role === 'tool';
}

export interface ThreadAssistantMessage extends BaseThreadMessage {
    message: AssistantMessage;
    metadata: FrameworkAssistantMessageMetadata;
    feedback: IThreadMessageFeedback | null;
}

export function isThreadAssistantMessage(
    m: IThreadMessage,
): m is ThreadAssistantMessage {
    return m.message.role === 'assistant';
}

export function threadMessageToFrameworkMessage(
    m: IThreadMessage,
): FrameworkMessage {
    if (isThreadSystemMessage(m)) {
        return {
            role: 'system',
            content: m.message.content,
            metadata: m.metadata,
        };
    } else if (isThreadUserMessage(m)) {
        return {
            role: 'user',
            content: m.message.content,
            metadata: m.metadata,
        };
    } else if (isThreadAssistantMessage(m)) {
        return {
            role: 'assistant',
            content: m.message.content,
            toolCalls: m.message.toolCalls,
            metadata: m.metadata,
            duration: m.message.duration,
        };
    } else if (isThreadToolMessage(m)) {
        return {
            role: 'tool',
            content: m.message.content,
            toolCallId: m.message.toolCallId,
            metadata: m.metadata,
        };
    } else {
        throw new Error('Unknown message type');
    }
}
