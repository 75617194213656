import { New } from '@zelros/interfaces-utils';
import { ToolDefinition } from '@zelros/threads';
import {
    getDefaultArgumentsBuilder,
    getDefaultCondition,
    getDefaultContentBuilder,
    getDefaultOverrideContentBuilder,
    IHook,
    IHookStep,
    ScriptArgument,
} from './base.hook.model';

export type IPostUserMessageHook = IHook; // No additional property for now

export interface IAgentPostUserMessageHook extends IPostUserMessageHook {
    /**
     * ID of the hook in DB
     */
    id: string;
}

export type BuiltinAgentPostUserMessageHook = IHook;

export const POST_USER_INITIAL_SCRIPT_ARGS: ScriptArgument[] = [
    {
        name: 'initialUserMessage',
        jsdoc: "{{role: 'user', content: string, metadata: object}} initialUserMessage The initial user message",
    },
];

export const POST_USER_SUBSEQUENT_SCRIPT_ARGS: ScriptArgument[] = [
    ...POST_USER_INITIAL_SCRIPT_ARGS,
    {
        name: 'currentMessages',
        jsdoc: "{{role: 'assistant' | 'tool', content: string | null, metadata: object}[]} currentMessages The current messages produced by this hook",
    },
];

export const DEFAULT_POST_USER_MESSAGE_HOOK_CONDITION = getDefaultCondition(
    POST_USER_INITIAL_SCRIPT_ARGS,
);

export const DEFAULT_POST_USER_MESSAGE_HOOK_STEP_CONDITION =
    getDefaultCondition(POST_USER_SUBSEQUENT_SCRIPT_ARGS);

export const DEFAULT_POST_USER_MESSAGE_HOOK_STEP_CONTENT_BUILDER =
    getDefaultContentBuilder(POST_USER_SUBSEQUENT_SCRIPT_ARGS);

export const DEFAULT_POST_USER_MESSAGE_HOOK_STEP_OVERRIDE_CONTENT_BUILDER =
    getDefaultOverrideContentBuilder(POST_USER_SUBSEQUENT_SCRIPT_ARGS);

export const DEFAULT_POST_USER_MESSAGE_HOOK_STEP: IHookStep = {
    name: 'My post-user step',
    condition: DEFAULT_POST_USER_MESSAGE_HOOK_STEP_CONDITION,
    contentBuilder: DEFAULT_POST_USER_MESSAGE_HOOK_STEP_CONTENT_BUILDER,
    overrideContentBuilder: null,
};

export const DEFAULT_POST_USER_MESSAGE_HOOK: New<IAgentPostUserMessageHook> = {
    name: 'My post-user-message hook',
    condition: DEFAULT_POST_USER_MESSAGE_HOOK_CONDITION,
    steps: [DEFAULT_POST_USER_MESSAGE_HOOK_STEP],
};

export function getDefaultPostUserMessageHookStepToolCallArgsBuilder(
    definition: ToolDefinition,
): string {
    return getDefaultArgumentsBuilder(
        POST_USER_SUBSEQUENT_SCRIPT_ARGS,
        definition,
    );
}
