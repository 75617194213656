import { New } from '@zelros/interfaces-utils';
import { ITrackingEvent } from './tracking-event.model';

export interface CreateTrackingEventDTO {
    type: string;
    source: string;
    context: Record<string, any>;
}

export function toCreateTrackingEventDTO(
    event: New<ITrackingEvent>,
): CreateTrackingEventDTO {
    return {
        type: event.type,
        source: event.source,
        context: event.context,
    };
}
