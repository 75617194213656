import { Language } from '@zelros/interfaces-utils';
import { ToolDefinition } from '@zelros/threads';
import { JSONSchema4 } from 'json-schema';
import { makeHumanFriendly } from '../../tool';
import { BuiltinAgentPostToolMessageHook } from './post-tool-message.hook.model';
import { BuiltinAgentPostUserMessageHook } from './post-user-message.hook.model';

export interface IHook {
    /**
     * Name of the hook
     */
    name: string;

    /**
     * Custom script evaluated to decide if the hook should be triggered
     */
    condition: string | null;

    /**
     * Steps to execute when the hook is triggered
     */
    steps: IHookStep[];
}

/**
 * condition + either contentBuilder or toolCall or both
 */
export type IHookStep =
    | IHookStepContent
    | IHookStepToolCall
    | (IHookStepContent & IHookStepToolCall);

interface IHookStepBase {
    /**
     * Name of the step
     */
    name: string;

    /**
     * Custom script evaluated to decide if the step should be executed
     */
    condition: string;
}

export interface IHookStepContent extends IHookStepBase {
    /**
     * Custom script evaluated to build the content of the "assistant" message
     *
     * Should be of the form:
     *
     * export default function(initialAssistantMessage, triggeringToolMessage, otherToolMessages, currentMessages) {
     *      // Value returned by the execution of the tool that triggered this workflow
     *      const previousToolCallResult = triggeringToolMessage.metadata.value;
     *
     *      // Do something with the value (format as cards, etc.)
     *      return '...';
     * }
     */
    contentBuilder: string;

    /**
     * Message to display in the Zelros App instead of the underlying content that is actually part of the conversation
     */
    overrideContentBuilder: string | null;
}

export interface IHookStepToolCall extends IHookStepBase {
    toolCall: {
        name: string;

        /**
         * Custom script evaluated to build the arguments of the tool call
         */
        argumentsBuilder: string;
    };
}

export function isHookStepContent(step: IHookStep): step is IHookStepContent {
    return 'contentBuilder' in step && step.contentBuilder !== undefined;
}

export function isHookStepToolCall(step: IHookStep): step is IHookStepToolCall {
    return 'toolCall' in step && step.toolCall !== undefined;
}

export interface ScriptArgument {
    name: string;
    jsdoc: string;
}

/**
 * Generate a JSDoc block for a script
 * @param scriptArguments
 * @param returnSchema
 */
export function getJSDoc(
    scriptArguments: ScriptArgument[],
    returnSchema?: JSONSchema4,
): string {
    const paramsBlock = scriptArguments
        .map((scriptArg) => ` * @param ${scriptArg.jsdoc}`)
        .join('\n');

    let returnBlock = '';
    if (returnSchema) {
        const returnSchemaString = JSON.stringify(returnSchema, null, 4)
            .split('\n')
            .map((line) => ` * ${line}`)
            .join('\n');
        returnBlock = `* \n * The return must respect the following JSON schema:
${returnSchemaString}`;
    }

    return `/**\n${paramsBlock}${returnBlock}\n */`;
}

/**
 * Generate a signature for a script
 * @param scriptArguments
 */
export function getSignature(scriptArguments: ScriptArgument[]): string {
    return scriptArguments.map((scriptArg) => scriptArg.name).join(', ');
}

/**
 * Generate a default condition script
 * @param scriptArguments
 */
export function getDefaultCondition(scriptArguments: ScriptArgument[]): string {
    const jsdoc = getJSDoc(scriptArguments);
    const signature = getSignature(scriptArguments);

    return `
${jsdoc}
export default function(${signature}) {
    return true;
}`;
}

/**
 * Generate a default content script
 * @param scriptArguments
 */
export function getDefaultContentBuilder(
    scriptArguments: ScriptArgument[],
): string {
    const jsdoc = getJSDoc(scriptArguments);
    const signature = getSignature(scriptArguments);

    return `
${jsdoc}
export default function(${signature}) {
    return 'Congrats! Your hook has been triggered!';
}`;
}

/**
 * Generate a default override content script
 * @param scriptArguments
 */
export function getDefaultOverrideContentBuilder(
    scriptArguments: ScriptArgument[],
): string {
    const jsdoc = getJSDoc(scriptArguments);
    const signature = getSignature(scriptArguments);

    return `
${jsdoc}
export default function(${signature}) {
    /**
     * return a string to override the original content with another content
     * return null to hide the original content
     * return undefined to have no effect on the original content
     */
    return 'This message will appear in the conversation while a different message is actually in the context';
}`;
}

/**
 * Generate a default tool call arguments builder script
 * @param scriptArguments
 * @param definition
 */
export function getDefaultArgumentsBuilder(
    scriptArguments: ScriptArgument[],
    definition: ToolDefinition,
): string {
    const humanFriendlyDefinition = makeHumanFriendly(definition, Language.EN);
    const jsdoc = getJSDoc(
        scriptArguments,
        humanFriendlyDefinition.function.parameters,
    );
    const signature = getSignature(scriptArguments);

    const returnValue = generateRandomObjectResponseForSchema(
        humanFriendlyDefinition.function.parameters,
    );

    const indent = ' '.repeat(4);

    return `
${jsdoc}
export default function(${signature}) {
    return ${JSON.stringify(returnValue, null, 4)
        .split('\n')
        .map((line, index) => (index === 0 ? line : indent + line))
        .join('\n')};
}`;
}

export function generateRandomObjectResponseForSchema(
    schema: JSONSchema4,
): any {
    if (!schema.type) return null;

    switch (schema.type) {
        case 'string': {
            if (schema.enum) {
                return schema.enum[
                    Math.floor(Math.random() * schema.enum.length)
                ];
            }
            return 'random_' + Math.random().toString(36).substring(7);
        }
        case 'number':
        case 'integer': {
            const min = schema.minimum || 0;
            const max = schema.maximum || 100;
            const num = Math.random() * (max - min) + min;
            return schema.type === 'integer' ? Math.floor(num) : num;
        }
        case 'boolean': {
            return Math.random() > 0.5;
        }

        case 'array': {
            const minItems = schema.minItems || 0;
            const maxItems = schema.maxItems || 5;
            const itemCount =
                Math.floor(Math.random() * (maxItems - minItems + 1)) +
                minItems;
            return Array.from({ length: itemCount }, () =>
                generateRandomObjectResponseForSchema(
                    schema.items as JSONSchema4,
                ),
            );
        }

        case 'object': {
            const result: Record<string, any> = {};
            const required = (schema.required || []) as string[];

            for (const prop in schema.properties) {
                if (required.includes(prop) || Math.random() > 0.3) {
                    result[prop] = generateRandomObjectResponseForSchema(
                        schema.properties[prop],
                    );
                }
            }
            return result;
        }

        case 'null':
            return null;

        default:
            return null;
    }
}

export type HookType = 'post_user' | 'post_tool';

export interface BuiltinAgentPostUserMessageGroup {
    title: string;
    hooks: BuiltinAgentPostUserMessageHook[];
}

export interface BuiltinAgentPostToolMessageGroup {
    title: string;
    hooks: BuiltinAgentPostToolMessageHook[];
}
