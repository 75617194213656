import { ScoredSourceMetadata } from '@zelros/knowledge-interfaces';

export enum ExecutableToolResultType {
    DOCUMENT = 'document',
    DATA = 'data',
    EXTERNAL = 'external',
}

export interface ExecutableToolResultSource {
    type: ExecutableToolResultType;
    updatedAt: Date | null;
}

export interface ExecutableToolResultSourceDocument
    extends ExecutableToolResultSource,
        ScoredSourceMetadata {
    type: ExecutableToolResultType.DOCUMENT;
    updatedAt: Date;
}

export function isExecutableToolResultSourceDocument(
    source: ExecutableToolResultSource,
): source is ExecutableToolResultSourceDocument {
    return source.type === ExecutableToolResultType.DOCUMENT;
}

export interface ExecutableToolResultSourceData
    extends ExecutableToolResultSource {
    type: ExecutableToolResultType.DATA;
    data: unknown;
}

export function isExecutableToolResultSourceData(
    source: ExecutableToolResultSource,
): source is ExecutableToolResultSourceData {
    return source.type === ExecutableToolResultType.DATA;
}

export interface ExecutableToolResultSourceExternal
    extends ExecutableToolResultSource {
    type: ExecutableToolResultType.EXTERNAL;
    title: string;
    content: string;
}

export function isExecutableToolResultSourceExternal(
    source: ExecutableToolResultSource,
): source is ExecutableToolResultSourceExternal {
    return source.type === ExecutableToolResultType.EXTERNAL;
}

export interface ExecutableToolResultAction {
    /**
     * An ID of the action, can help remove duplicates when multiple similar actions are returned by different tools
     */
    id?: string;
    /**
     * The label of the button
     */
    label: string;
    /**
     * The query that will either be sent directly to the agent or placed in the invite
     */
    query: string;
    /**
     * Whether to send the query to the agent directly or place it in the invite to allow some modifications by the user
     */
    immediate: boolean;
}
