import type { FrontendBootstrapConfig } from '@zelros/standalone-interfaces';
import {
    createContext,
    createResource,
    type ParentComponent,
    Show,
    useContext,
} from 'solid-js';
import { FullscreenLoading } from '~/components/FullscreenLoading';
import { BASE_URL } from '~/Constants';

export const BootstrapConfigContext = createContext<FrontendBootstrapConfig>();

/**
 * Exposes the bootstrap config to the application.
 *
 * The bootstrap config is the minimal config that the app requires when starting up. It includes:
 * - the authentication config
 * - the analytics ID
 * Fetching the bootstrap config doesn't require authentication.
 *
 * @param props
 * @constructor
 */
export const BootstrapConfigProvider: ParentComponent = (props) => {
    const [config] = createResource(async () => {
        const response = await fetch(
            `${BASE_URL}/api/standalone/config/bootstrap`,
        );
        return (await response.json()) as FrontendBootstrapConfig;
    });

    return (
        <Show when={!config.loading} fallback={<FullscreenLoading />}>
            <BootstrapConfigContext.Provider value={config()}>
                {props.children}
            </BootstrapConfigContext.Provider>
        </Show>
    );
};

export const useBootstrapConfig = () => {
    const config = useContext(BootstrapConfigContext);
    if (config === undefined) {
        throw new Error(
            'useBootstrapConfig must be used within a BootstrapConfigProvider',
        );
    }
    return config;
};
