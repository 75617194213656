import { ScoredSourceMetadataDTO } from '@zelros/knowledge-interfaces';
import {
    ExecutableToolResultSource,
    ExecutableToolResultSourceData,
    ExecutableToolResultSourceDocument,
    ExecutableToolResultSourceExternal,
    ExecutableToolResultType,
    isExecutableToolResultSourceData,
    isExecutableToolResultSourceDocument,
    isExecutableToolResultSourceExternal,
} from './executable.model';

export interface ExecutableToolResultSourceDTO {
    type: string;
    updated_at?: string;
}

export function executableToolResultSourceFromDTO(
    dto: ExecutableToolResultSourceDTO,
): ExecutableToolResultSource {
    if (isExecutableToolResultSourceDocumentDTO(dto)) {
        return executableToolResultSourceDocumentFromDTO(dto);
    } else if (isExecutableToolResultSourceDataDTO(dto)) {
        return executableToolResultSourceDataFromDTO(dto);
    } else if (isExecutableToolResultSourceExternalDTO(dto)) {
        return executableToolResultSourceExternalFromDTO(dto);
    }

    throw new Error(`Unknown source type: ${dto.type}`);
}

export function toExecutableToolResultSourceDTO(
    source: ExecutableToolResultSource,
): ExecutableToolResultSourceDTO {
    if (isExecutableToolResultSourceDocument(source)) {
        return toExecutableToolResultSourceDocumentDTO(source);
    } else if (isExecutableToolResultSourceData(source)) {
        return toExecutableToolResultSourceDataDTO(source);
    } else if (isExecutableToolResultSourceExternal(source)) {
        return toExecutableToolResultSourceExternalDTO(source);
    }

    throw new Error(`Unknown source type: ${source.type}`);
}

export interface ExecutableToolResultSourceDocumentDTO
    extends ExecutableToolResultSourceDTO,
        ScoredSourceMetadataDTO {
    type: 'document';
    updated_at: string;
}

export function executableToolResultSourceDocumentFromDTO(
    dto: ExecutableToolResultSourceDocumentDTO,
): ExecutableToolResultSourceDocument {
    // We may retrieve from the DB or ES metadata that were not indexed correctly
    if (!dto.updated_at) {
        dto.updated_at = new Date().toISOString();
    }

    return {
        type: ExecutableToolResultType.DOCUMENT,
        id: dto.id,
        title: dto.title,
        unitary: dto.unitary,
        fromUpload: dto.from_upload,
        lines: dto.lines,
        spans: dto.spans,
        tags: dto.tags,
        score: dto.score,
        updatedAt: new Date(dto.updated_at),
    };
}

export function toExecutableToolResultSourceDocumentDTO(
    source: ExecutableToolResultSourceDocument,
): ExecutableToolResultSourceDocumentDTO {
    return {
        type: 'document',
        id: source.id,
        title: source.title,
        unitary: source.unitary,
        from_upload: source.fromUpload,
        lines: source.lines,
        spans: source.spans,
        tags: source.tags,
        score: source.score,
        // We may retrieve metadata that were not indexed correctly
        updated_at: source.updatedAt.toISOString(),
    };
}

export function isExecutableToolResultSourceDocumentDTO(
    source: ExecutableToolResultSourceDTO,
): source is ExecutableToolResultSourceDocumentDTO {
    return source.type === 'document';
}

export interface ExecutableToolResultSourceDataDTO
    extends ExecutableToolResultSourceDTO {
    data: unknown;
}

export function executableToolResultSourceDataFromDTO(
    dto: ExecutableToolResultSourceDataDTO,
): ExecutableToolResultSourceData {
    return {
        type: ExecutableToolResultType.DATA,
        data: dto.data,
        updatedAt: null,
    };
}

export function toExecutableToolResultSourceDataDTO(
    source: ExecutableToolResultSourceData,
): ExecutableToolResultSourceDataDTO {
    return {
        type: 'data',
        data: source.data,
    };
}

export function isExecutableToolResultSourceDataDTO(
    source: ExecutableToolResultSourceDTO,
): source is ExecutableToolResultSourceDataDTO {
    return source.type === 'data';
}

export interface ExecutableToolResultSourceExternalDTO
    extends ExecutableToolResultSourceDTO {
    type: 'external';
    title: string;
    content: string;
}

export function executableToolResultSourceExternalFromDTO(
    dto: ExecutableToolResultSourceExternalDTO,
): ExecutableToolResultSourceExternal {
    return {
        type: ExecutableToolResultType.EXTERNAL,
        title: dto.title,
        content: dto.content,
        updatedAt: dto.updated_at ? new Date(dto.updated_at) : null,
    };
}

export function toExecutableToolResultSourceExternalDTO(
    source: ExecutableToolResultSourceExternal,
): ExecutableToolResultSourceExternalDTO {
    return {
        type: 'external',
        title: source.title,
        content: source.content,
        updated_at: source.updatedAt?.toISOString(),
    };
}

export function isExecutableToolResultSourceExternalDTO(
    source: ExecutableToolResultSourceDTO,
): source is ExecutableToolResultSourceExternalDTO {
    return source.type === 'external';
}

export interface ExecutableToolResultActionDTO {
    id?: string;
    label: string;
    query: string;
    immediate: boolean;
}
