import { CustomerContextDecision, DecisionValue } from './decision.model';

export interface DecisionDTO {
    id: string;
    value: string;
    product: {
        id: string;
    };
    customer_session_id: string;
    created_by: {
        id: string;
        username: string;
    };
    created_at: string;
    updated_at: string;
}

export function toDecisionDTO(decision: CustomerContextDecision): DecisionDTO {
    return {
        id: decision.id,
        value: decision.value,
        product: {
            id: decision.product.id,
        },
        customer_session_id: decision.customerSessionId,
        created_by: decision.createdBy,
        created_at: decision.createdAt.toISOString(),
        updated_at: decision.updatedAt.toISOString(),
    };
}

export function decisionFromDTO(dto: DecisionDTO): CustomerContextDecision {
    return {
        id: dto.id,
        value: dto.value as DecisionValue,
        product: {
            id: dto.product.id,
        },
        customerSessionId: dto.customer_session_id,
        createdBy: dto.created_by,
        createdAt: new Date(dto.created_at),
        updatedAt: new Date(dto.updated_at),
    };
}

export interface CustomerContextDecisionDTO {
    value: string;
    product: {
        id: string;
    };
}

export function toCustomerContextDecisionDTO(
    decision: CustomerContextDecision,
): CustomerContextDecisionDTO {
    return {
        value: decision.value,
        product: {
            id: decision.product.id,
        },
    };
}
