import { css } from 'solid-styled';

export function Skeleton() {
    css`
        .skeleton {
            background-color: #e9eeff;
            background: linear-gradient(
                    100deg,
                    rgba(255, 255, 255, 0) 40%,
                    rgba(255, 255, 255, 0.5) 50%,
                    rgba(255, 255, 255, 0) 60%
                )
                #e9eeff;
            background-size: 200% 100%;
            background-position-x: 180%;
            animation: 1s loading ease-in-out infinite;
        }

        @keyframes loading {
            to {
                background-position-x: -20%;
            }
        }
    `;
    return (
        <div class="w-full">
            <div class="flex h-2 w-full">
                <div class="skeleton h-2 w-1/5 grow rounded " />
                <div class="skeleton ml-2 h-2 w-2/5 grow rounded" />
                <div class="skeleton ml-2 h-2 w-2/5 grow rounded" />
            </div>
            <div class="mt-2 flex h-2 w-full">
                <div class="skeleton w-half h-2 grow rounded opacity-50" />
                <div class="skeleton w-half ml-2 h-2 grow rounded opacity-50" />
            </div>
            <div class="mb-2 mt-2 flex h-2 w-full">
                <div class="skeleton h-2 w-1/4 grow rounded opacity-25" />
                <div class="skeleton ml-2 h-2 w-3/4 grow rounded opacity-25" />
            </div>
        </div>
    );
}
