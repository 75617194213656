import { initClarity } from '@zelros/analytics-interfaces';
import { useBootstrapConfig } from '~/config/BootstrapConfigProvider';

export const Analytics = () => {
    const bootstrapConfig = useBootstrapConfig();

    if (bootstrapConfig.analytics.id) {
        initClarity(bootstrapConfig.analytics.id);
    }
    return null;
};
