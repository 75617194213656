import type { FrontendConfig } from '@zelros/standalone-interfaces';
import {
    createContext,
    createEffect,
    createResource,
    type ParentComponent,
    Show,
    useContext,
} from 'solid-js';
import { useAuth } from '~/auth/AuthContext';
import { FullscreenLoading } from '~/components/FullscreenLoading';
import { BASE_URL } from '~/Constants';
import { useLogging } from '~/logging/LoggingContext';

export const ConfigContext = createContext<FrontendConfig>();

/**
 * Exposes the config to the application.
 *
 * @param props
 * @constructor
 */
export const ConfigProvider: ParentComponent = (props) => {
    const { log } = useLogging();
    const { authHeader } = useAuth();

    log('ConfigProvider: Initializing ConfigProvider...');

    const [config] = createResource(async () => {
        const response = await fetch(`${BASE_URL}/api/standalone/config`, {
            headers: {
                ...authHeader(),
            },
        });
        return (await response.json()) as FrontendConfig;
    });

    createEffect(() => {
        const res = config();
        if (res) {
            const style = document.documentElement.style;
            style.setProperty(
                '--main-color-tw',
                getRGBColor(res.homepage.lookAndFeel.colors.main),
            );
            style.setProperty(
                '--main-color',
                res.homepage.lookAndFeel.colors.main,
            );
            style.setProperty(
                '--decision-support-h1',
                res.magicAnswer.lookAndFeel.text.h1.color,
            );
            style.setProperty(
                '--decision-support-h2',
                res.magicAnswer.lookAndFeel.text.h2.color,
            );
            style.setProperty(
                '--decision-support-h3',
                res.magicAnswer.lookAndFeel.text.h3.color,
            );
            style.setProperty(
                '--decision-support-h4',
                res.magicAnswer.lookAndFeel.text.h4.color,
            );
            style.setProperty(
                '--decision-support-link',
                res.magicAnswer.lookAndFeel.text.href.color,
            );
            style.setProperty(
                '--decision-support-list',
                res.magicAnswer.lookAndFeel.text.ul.color,
            );
            style.setProperty(
                '--decision-support-strong',
                res.magicAnswer.lookAndFeel.text.strong.color,
            );
        }
    });

    return (
        <Show when={!config.loading} fallback={<FullscreenLoading />}>
            <ConfigContext.Provider value={config()}>
                {props.children}
            </ConfigContext.Provider>
        </Show>
    );
};

export const useConfig = () => {
    const config = useContext(ConfigContext);
    if (config === undefined) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return config;
};

function getRGBColor(hex: string) {
    const color = hex.replace(/#/g, '');
    const r = Number.parseInt(color.slice(0, 2), 16);
    const g = Number.parseInt(color.slice(2, 4), 16);
    const b = Number.parseInt(color.slice(4, 6), 16);

    return `${r}, ${g}, ${b}`;
}
