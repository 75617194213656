import {
    ITrackingEvent,
    toCreateTrackingEventDTO,
    TrackingEventSource,
} from '@zelros/advisor-app-interfaces';
import { New } from '@zelros/interfaces-utils';
import { createContext, createEffect, JSX } from 'solid-js';
import { useAuth } from '~/auth/AuthContext';
import { BASE_URL } from '~/Constants';
import { useLogging } from '~/logging/LoggingContext';
import { TopicMessage, useMessaging } from '~/messaging/MessagingContext';

interface TopicMessageTracking extends TopicMessage {
    topic: 'tracking';
    payload: {
        type: string;
        context: Record<string, unknown>;
    };
}

function isTopicMessageTracking(
    message: TopicMessage,
): message is TopicMessageTracking {
    return message.topic === 'tracking';
}

function isTopicMessageInteresting(m: TopicMessage): boolean {
    return isTopicMessageTracking(m);
}

const TrackingContext = createContext();

export const TrackingProvider = (props: { children: JSX.Element }) => {
    const { log } = useLogging();
    const { message } = useMessaging();
    const { authHeader } = useAuth();

    log('TrackingProvider: Initializing TrackingProvider...');

    const baseURL = `${BASE_URL}/advisor-app/tracking/events`;

    /**
     * React to "tracking" messages from the Live Extractor SDK
     */
    createEffect(async () => {
        const m = message();

        if (m && isTopicMessageInteresting(m)) {
            log('ThreadContainer: Running message effect...', m);

            if (isTopicMessageTracking(m)) {
                await handleTopicMessageTracking(m);
            }
        }
    });

    const handleTopicMessageTracking = async (m: TopicMessageTracking) => {
        log('TrackingProvider: Handling tracking message...', m);

        const { type, context } = m.payload;

        const event: New<ITrackingEvent> = {
            type,
            source: TrackingEventSource.LiveExtractor,
            context,
            date: new Date(),
        };

        const dto = toCreateTrackingEventDTO(event);

        await fetch(baseURL, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
            body: JSON.stringify(dto),
        });
    };

    return (
        <TrackingContext.Provider value={{}}>
            {props.children}
        </TrackingContext.Provider>
    );
};
