import { Dialog } from '@ark-ui/solid';
import type { ComponentProps } from 'solid-js';
import { tv } from 'tailwind-variants';
import { createStyleContext } from '~/lib/create-style-context';

const styles = tv(
    {
        base: 'dialog',
        slots: {
            trigger: 'dialog__trigger',
            backdrop: 'dialog__backdrop',
            positioner: 'dialog__positioner',
            content: 'dialog__content',
            title: 'dialog__title',
            description: 'dialog__description',
            closeTrigger: 'dialog__closeTrigger',
        },
        variants: {},
    },
    { twMerge: false },
);
const { withProvider, withContext } = createStyleContext(styles);

export const Root = withProvider(Dialog.Root);
export const Backdrop = withContext(Dialog.Backdrop, 'backdrop');
export const CloseTrigger = withContext(Dialog.CloseTrigger, 'closeTrigger');
export const Content = withContext(Dialog.Content, 'content');
export const Description = withContext(Dialog.Description, 'description');
export const Positioner = withContext(Dialog.Positioner, 'positioner');
export const Title = withContext(Dialog.Title, 'title');
export const Trigger = withContext(Dialog.Trigger, 'trigger');

export type RootProps = ComponentProps<typeof Root>;
export type BackdropProps = ComponentProps<typeof Backdrop>;
export type CloseTriggerProps = ComponentProps<typeof CloseTrigger>;
export type ContentProps = ComponentProps<typeof Content>;
export type DescriptionProps = ComponentProps<typeof Description>;
export type PositionerProps = ComponentProps<typeof Positioner>;
export type TitleProps = ComponentProps<typeof Title>;
export type TriggerProps = ComponentProps<typeof Trigger>;
