import {
    LifeEventDisplayDTO,
    LifeEventDisplayForActivityDTO,
    lifeEventDisplayFromDTO,
    toLifeEventDisplayDTO,
    toLifeEventDisplayForActivityDTO,
} from '../life-event';
import { Opportunity } from './opportunity.model';
import {
    RecommendationDTO,
    RecommendationForActivityDTO,
    recommendationFromDTO,
    toRecommendationDTO,
    toRecommendationForActivityDTO,
} from './recommendation.dto';

export interface OpportunityDTO {
    id: string;
    life_event: LifeEventDisplayDTO;
    recommendations: RecommendationDTO[];
}

export interface OpportunityForActivityDTO {
    id: string;
    life_event: LifeEventDisplayForActivityDTO;
    recommendations: RecommendationForActivityDTO[];
}

export function toOpportunityDTO(opportunity: Opportunity): OpportunityDTO {
    return {
        id: opportunity.id,
        life_event: toLifeEventDisplayDTO(opportunity.lifeEventDisplay),
        recommendations: opportunity.recommendations.map(toRecommendationDTO),
    };
}

export function opportunityFromDTO(dto: OpportunityDTO): Opportunity {
    return {
        id: dto.id,
        lifeEventDisplay: lifeEventDisplayFromDTO(dto.life_event),
        recommendations: dto.recommendations.map(recommendationFromDTO),
    };
}

export function toOpportunityForActivityDTO(
    opportunity: Opportunity,
): OpportunityForActivityDTO {
    return {
        id: opportunity.id,
        life_event: toLifeEventDisplayForActivityDTO(
            opportunity.lifeEventDisplay,
        ),
        recommendations: opportunity.recommendations.map(
            toRecommendationForActivityDTO,
        ),
    };
}
