import { AnswerValue } from './answer';
import { QuestionDisplay } from './question-display.model';
import {
    QuestionDetails,
    QuestionModalities,
    QuestionType,
} from './question.model';

export interface QuestionDisplayDTO {
    id: string;
    resource_id: string; // Deprecated, use resource.id instead
    resource: {
        id: string;
        created_at: string;
        updated_at: string;
    };
    title: string;
    type: string;
    details: QuestionDetails;
    text: string;
    modalities: QuestionModalities;
    position: number;
    answer: { value: AnswerValue } | null;
    data: Record<string, any>;
}

export function toQuestionDisplayDTO(
    display: QuestionDisplay,
): QuestionDisplayDTO {
    return {
        id: display.id,
        resource_id: display.question.id,
        resource: {
            id: display.question.id,
            created_at: display.question.createdAt.toISOString(),
            updated_at: display.question.updatedAt.toISOString(),
        },
        title: display.title,
        type: display.type,
        details: display.details,
        text: display.text,
        modalities: display.modalities,
        position: display.position,
        answer: display.answer ? { value: display.answer.value } : null,
        data: display.data,
    };
}

export function questionDisplayFromDTO(
    dto: QuestionDisplayDTO,
): QuestionDisplay {
    return {
        id: dto.id,
        question: {
            id: dto.resource.id,
            createdAt: new Date(dto.resource.created_at),
            updatedAt: new Date(dto.resource.updated_at),
        },
        title: dto.title,
        type: dto.type as QuestionType,
        details: dto.details,
        text: dto.text,
        modalities: dto.modalities,
        position: dto.position,
        answer: dto.answer ? { value: dto.answer.value } : null,
        data: dto.data,
    };
}

export interface QuestionDisplayForActivityDTO {
    id: string;
    resource_id: string;
    title: string;
    type: string;
    answer: { value: string | string[] } | null;
}

export function toQuestionDisplayForActivityDTO(
    display: QuestionDisplay,
): QuestionDisplayForActivityDTO {
    return {
        id: display.id,
        resource_id: display.question.id,
        title: display.title,
        type: display.type,
        answer: display.answer ? { value: display.answer.value } : null,
    };
}
