import { createRoot, createSignal } from 'solid-js';

export const i18n = createRoot(() => {
    const [language, setLanguage] = createSignal<string | undefined>(undefined);

    return {
        language,
        setLanguage,
    };
});
