import { createMediaQuery } from '@solid-primitives/media';

/**
 * @deprecated
 */
export const config = {
    authUrl: import.meta.env.VITE_AUTH_URL || '/auth',
    baseUrl: import.meta.env.VITE_BASE_URL || '',
};

export const BASE_URL = import.meta.env.VITE_BASE_URL || '';
export const AUTH_URL = import.meta.env.VITE_AUTH_URL || '/auth';

export const isLarge = createMediaQuery('(min-width: 768px)', true);
