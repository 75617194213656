import { Sort, sortFromDTO } from '@zelros/interfaces-utils';
import { DEFAULT_TOOL_QUERY_DTO, ToolQueryDTO } from './query.dto';

export interface ToolQuery extends Sort {
    sortBy: ToolSortBy | null;
}

export enum ToolSortBy {
    Name = 'name',
    Type = 'type',
    UpdatedAt = 'updatedAt',
}

export const DEFAULT_TOOL_QUERY = toolQueryFromDTO(DEFAULT_TOOL_QUERY_DTO);

export function toolQueryFromDTO(dto: ToolQueryDTO): ToolQuery {
    return sortFromDTO(dto) as ToolQuery;
}
