import { io, type Socket } from 'socket.io-client';
import {
    type Accessor,
    createContext,
    createEffect,
    createSignal,
    type JSX,
    onCleanup,
    useContext,
} from 'solid-js';
import { useAuth } from '~/auth/AuthContext';
import { useConfig } from '~/config/ConfigProvider';
import { BASE_URL } from '~/Constants';
import { useLogging } from '~/logging/LoggingContext';

export type AckCallback = () => void;

interface TopicMessageDTO {
    id: string;
    topic: string;
    payload?: any;
}

export class TopicMessage {
    constructor(
        public readonly id: string,
        public readonly topic: string,
        public readonly ack: AckCallback,
        public readonly payload?: unknown,
    ) {}
}

interface MessagingContextType {
    message: Accessor<TopicMessage | undefined>;
    send: (topic: string, payload: unknown) => void;
}

const MessagingContext = createContext<MessagingContextType>();

const PING_EVENT = '_ping';
const PONG_EVENT = '_pong';
const MESSAGE_EVENT = 'message';
const ACK_EVENT = 'ack';

export const MessagingProvider = (props: { children: JSX.Element }) => {
    const { log } = useLogging();
    const { authenticated } = useAuth();
    const config = useConfig();

    log('MessagingProvider: Initializing MessagingProvider...');

    const baseUrl = `${BASE_URL}/messaging`;

    let socket: Socket | null = null;

    const [message, setMessage] = createSignal<TopicMessage>();

    const transports = ['polling'];
    if (config.magicAnswer.socketio.websockets) {
        transports.unshift('websocket');
    }

    createEffect(() => {
        log('MessagingContext: Running MessagingProvider effect...');
        if (authenticated()) {
            // Initiate the socket connection
            log('MessagingContext: Initiating messaging socket connection...');
            socket = io({
                path: `${baseUrl}/socket.io`,
                secure: true,
                query: {
                    source: 'assistant',
                },
                transports,
            });

            // Bind event listeners
            socket.on('connect', () => {
                log('MessagingContext: Connected to messaging socket');
            });
            socket.on('disconnect', () => {
                log('MessagingContext: Disconnected from messaging socket');
            });
            socket.on('error', (error: unknown) => {
                log('MessagingContext: Error on messaging socket', error);
            });

            onCleanup(() => {
                log(
                    'MessagingContext: Cleaning up messaging socket connection...',
                );
                socket?.disconnect();
            });

            // Handle ping/pong protocol
            socket.on(PING_EVENT, () => {
                log('MessagingContext: Ping received');
                socket?.emit(PONG_EVENT);
            });

            socket.on(MESSAGE_EVENT, (dto: TopicMessageDTO) => {
                log(
                    `MessagingContext: Message received: ${dto.topic}`,
                    dto.payload,
                );
                const message = new TopicMessage(
                    dto.id,
                    dto.topic,
                    () => {
                        log(
                            `MessagingContext: Acknowledging message: ${dto.id}...`,
                        );
                        socket?.emit(ACK_EVENT, message.id);
                        setMessage(undefined);
                    },
                    dto.payload,
                );
                setMessage(message);
            });
        } else {
            log('MessagingContext: Disconnecting messaging socket...');
            socket?.disconnect();
            socket = null;
        }
    });

    const send = (name: string, data: unknown) => {
        log(`MessagingContext: Sending message: ${name}`, data);
        socket?.emit(name, data);
    };

    return (
        <MessagingContext.Provider value={{ message, send }}>
            {props.children}
        </MessagingContext.Provider>
    );
};

export const useMessaging = () => {
    const context = useContext(MessagingContext);
    if (!context) {
        throw new Error('useMessaging must be used within a MessagingProvider');
    }
    return context;
};
