import { sleep } from '@zelros/interfaces-utils';

import {
    type AddMessageToThreadDTO,
    isThreadUserMessage,
    type IThread,
    type IThreadMessage,
    SubmitThreadFeedbackDTO,
    type ThreadDTO,
    threadFromDTO,
    type ThreadMessageDTO,
    threadMessageFromDTO,
} from '@zelros/standalone-interfaces';
import type { Operation } from 'fast-json-patch';
import { sortBy, uniqBy } from 'lodash-es';
import { type Socket } from 'socket.io-client';
import {
    type Accessor,
    batch,
    createContext,
    createEffect,
    createResource,
    createSignal,
    type JSX,
    onCleanup,
    type Resource,
    untrack,
    useContext,
} from 'solid-js';
import { useAuth } from '~/auth/AuthContext';
import { useConfig } from '~/config/ConfigProvider';
import { BASE_URL } from '~/Constants';
import { useLogging } from '~/logging/LoggingContext';
import { useThreadSocket } from '~/thread/ThreadSocketContext';
import { useRecentThreads } from './recent-threads/RecentThreadsContext';

const EMPTY_CONTENT = '';

/**
 * The interval (in milliseconds) at which we simulate typing in the thread stream.
 */
const THREAD_SMOOTHING_INTERVAL = 15;

interface ThreadContextType {
    thread: Resource<IThread | null>;
    threadMessages: Accessor<IThreadMessage[]>;
    threadStream: Accessor<string>;
    threadReady: Accessor<boolean>;
    createThread: (message: string, name?: string) => Promise<string>;
    createThreadFromCustomer: (externalId: string) => Promise<string>;
    sendMessageToThread: (
        id: string,
        message: string,
        name?: string,
    ) => Promise<void>;
    addCustomerProfilePatchesToThread: (
        id: string,
        externalId: string,
        patches: Operation[],
    ) => Promise<void>;
    runThread: (id: string) => Promise<void>;
    continueThread: (id: string) => Promise<void>;
    setThreadId: (id: string | undefined) => void;
    saveFeedback: (
        threadId: string,
        messageId: string,
        feedback: SubmitThreadFeedbackDTO,
    ) => Promise<void>;
    loadingCustomer: Accessor<boolean>;
    setLoadingCustomer: (loading: boolean) => void;
}

const ThreadContext = createContext<ThreadContextType>();

export const ThreadProvider = (props: { children: JSX.Element }) => {
    const { log } = useLogging();
    const { authHeader } = useAuth();
    const { socket } = useThreadSocket();
    const { addThread } = useRecentThreads();
    const config = useConfig();

    const baseUrl = `${BASE_URL}/api/standalone/threads`;

    const fetchThread = async (id: string): Promise<IThread | null> => {
        log('Fetching thread...', id);

        const res = await fetch(`${baseUrl}/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
        });
        const dto = (await res.json()) as ThreadDTO;
        const thread = threadFromDTO(dto);

        log('ThreadProvider: Thread fetched', thread);

        mergeMessages(thread.messages);

        log('ThreadProvider: Finished fetching thread', thread);

        return thread;
    };

    const createThread = async (
        message: string,
        name?: string,
    ): Promise<string> => {
        log('ThreadProvider: Creating thread...');

        blockThread();

        // Create the thread
        const createResponse = await fetch(baseUrl, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
        });
        const dto = (await createResponse.json()) as ThreadDTO;

        const thread = threadFromDTO(dto);

        // Add a message to it
        const addMessageToThreadDTO: AddMessageToThreadDTO = {
            content: message,
            name,
        };

        await fetch(`${baseUrl}/${thread.id}/messages`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
            body: JSON.stringify(addMessageToThreadDTO),
        });

        addThread({ id: thread.id, title: message });

        return thread.id;
    };

    const createThreadFromCustomer = async (
        externalId: string,
    ): Promise<string> => {
        log('ThreadProvider: Creating thread from customer...');
        setLoadingCustomer(true);

        blockThread();

        // Create the thread
        const createDTO = {
            external_id: externalId,
        };
        const createResponse = await fetch(`${baseUrl}/create-from-customer`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
            body: JSON.stringify(createDTO),
        });
        const dto = (await createResponse.json()) as ThreadDTO;

        const thread = threadFromDTO(dto);

        // Retrieve the content of the first user message as the title
        const title =
            thread.messages.find(isThreadUserMessage)?.message.content ?? '';

        addThread({
            id: thread.id,
            title,
        });

        return thread.id;
    };

    const addCustomerProfilePatchesToThread = async (
        id: string,
        externalId: string,
        patches: Operation[],
    ): Promise<void> => {
        blockThread();

        await fetch(`${baseUrl}/${id}/add-customer-profile-patches`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
            body: JSON.stringify({ external_id: externalId, patches }),
        });
    };

    const sendMessageToThread = async (
        id: string,
        message: string,
        name?: string,
    ): Promise<void> => {
        log('ThreadProvider: Sending message to thread...', id, message);

        blockThread();

        const addMessageToThreadDTO: AddMessageToThreadDTO = {
            content: message,
            name,
        };

        await fetch(`${baseUrl}/${id}/messages`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
            body: JSON.stringify(addMessageToThreadDTO),
        });

        log('ThreadProvider: Message sent');
    };

    const blockThread = () => {
        log('Blocking thread...');
        batch(() => {
            // Reset the stream
            setThreadStream(EMPTY_CONTENT);
            // Mark the stream as not ready (will be ready again when the stream is complete)
            setThreadReady(false);
        });
    };

    const unblockThread = () => {
        setThreadReady(true);
    };

    const runThread = async (id: string): Promise<void> => {
        log('Running thread...');

        await fetch(`${baseUrl}/${id}/runs`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
        });
    };

    const continueThread = async (id: string): Promise<void> => {
        await runThread(id);
        subscribeToThreadStream(id);
    };

    const subscribeToThreadStreamImpl = async (id: string): Promise<void> => {
        log('ThreadProvider: Subscribing to thread stream...', id);

        streamController = new AbortController();

        const res = await fetch(`${baseUrl}/${id}/stream`, {
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
            signal: streamController.signal,
        });

        if (!res.body || !res.ok) {
            unblockThread();
            throw new Error('Failed to fetch thread stream');
        }

        const reader = res.body.getReader();
        const decoder = new TextDecoder('utf-8');

        for (let done = false; !done; ) {
            // Sorry for this weird form but ESLint complains about "while (true) { ... }"
            const { done: streamDone, value } = await reader.read();
            done = streamDone;

            if (value) {
                const newText = decoder.decode(value, { stream: true });
                //log('ThreadProvider: Stream data received', newText);
                setThreadStream((prev) => prev + newText);
                // TODO Improve smoothing
                // Introduce a delay to simulate typing
                await sleep(THREAD_SMOOTHING_INTERVAL);
            } else {
                log('ThreadProvider: Stream complete');
                unblockThread();
            }
        }
    };

    const subscribeToThreadStreamPollingImpl = async (
        id: string,
    ): Promise<void> => {
        log('ThreadProvider: Subscribing to thread stream data...', id);

        let ended = false;

        while (!ended) {
            const res = await fetch(`${baseUrl}/${id}/stream/data`, {
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader(),
                },
            });

            const data = (await res.json()) as {
                text: string;
                ended: boolean;
            };

            batch(() => {
                setThreadStream(data.text);
                setThreadReady(data.ended);
            });

            ended = data.ended;

            await sleep(300);
        }
    };

    let subscribeToThreadStream: (id: string) => Promise<void>;
    if (config.magicAnswer.streaming.enabled) {
        subscribeToThreadStream = subscribeToThreadStreamImpl;
    } else {
        subscribeToThreadStream = subscribeToThreadStreamPollingImpl;
    }

    const unsubscribeFromThreadStream = (id: string) => {
        log('ThreadProvider: Unsubscribing from thread stream...', id);
        if (streamController) {
            streamController.abort();
        }
        unblockThread();
    };

    const onSocketMessage = (dto: ThreadMessageDTO) => {
        const message = threadMessageFromDTO(dto);
        log('ThreadProvider: Received new socket message', message);

        // Update the list of messages
        mergeMessages([message]);
    };

    const mergeMessages = (messages: IThreadMessage[]) => {
        log('ThreadProvider: Merging messages...');
        /**
         * We can't blindly replace the messages because we might have received new messages in the meantime.
         * So instead, we merge the arrays, based on the IDs of the messages and re-sort them by createdAt.
         */
        const merged = uniqBy([...untrack(threadMessages), ...messages], 'id');
        const sorted = sortBy(merged, 'createdAt');
        setThreadMessages(sorted);
    };

    const saveFeedback = async (
        threadId: string,
        messageId: string,
        feedback: SubmitThreadFeedbackDTO | null,
    ): Promise<void> => {
        await fetch(
            `${BASE_URL}/api/standalone/threads/${threadId}/messages/${messageId}/feedback`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader(),
                },
                body: JSON.stringify(feedback),
            },
        );
    };

    createEffect((previousSocket?: Socket) => {
        const currentSocket = socket();

        log('ThreadProvider: Running socket effect...');

        if (currentSocket && !previousSocket) {
            currentSocket.on('message', onSocketMessage);
            const id = untrack(threadId);
            if (id) {
                subscribeToSocketMessages(id);
            }
        } else if (previousSocket && !currentSocket) {
            previousSocket.off('message', onSocketMessage);
        }

        return currentSocket;
    });

    onCleanup(() => {
        const s = socket();
        if (s) {
            s.off('message', onSocketMessage);
        }
    });

    const subscribeToSocketMessages = (id: string) => {
        const s = untrack(socket);
        log('ThreadProvider: Subscribing to socket messages...', id);
        if (s) {
            s.emit('thread:subscribe', { threadId: id });
        }
    };

    const unsubscribeFromSocketMessages = (id: string) => {
        const s = untrack(socket);
        log('ThreadProvider: Unsubscribing from socket messages...', id);
        if (s) {
            s.emit('thread:unsubscribe', { threadId: id });
        }
    };

    // Thread
    const [threadId, setThreadId] = createSignal<string>();
    const [thread, { mutate: mutateThread }] = createResource(
        threadId,
        fetchThread,
    );
    const [threadMessages, setThreadMessages] = createSignal<IThreadMessage[]>(
        [],
    );
    const [threadStream, setThreadStream] = createSignal<string>(EMPTY_CONTENT);

    const [threadReady, setThreadReady] = createSignal<boolean>(true);

    const [loadingCustomer, setLoadingCustomer] = createSignal(false);

    let streamController: AbortController | null = null;

    createEffect((previousThreadId?: string) => {
        const currentThreadId = threadId();

        log('ThreadProvider: Running threadId effect...', currentThreadId);

        if (currentThreadId) {
            subscribeToSocketMessages(currentThreadId);
        } else {
            mutateThread(null);
        }

        if (previousThreadId) {
            unsubscribeFromThreadStream(previousThreadId);
            unsubscribeFromSocketMessages(previousThreadId);
        }

        batch(() => {
            setThreadStream(EMPTY_CONTENT);
            setThreadMessages([]);
        });

        return currentThreadId;
    });

    return (
        <ThreadContext.Provider
            value={{
                thread,
                createThread,
                createThreadFromCustomer,
                setThreadId,
                threadMessages,
                sendMessageToThread,
                runThread,
                continueThread,
                addCustomerProfilePatchesToThread,
                threadStream,
                threadReady,
                saveFeedback,
                loadingCustomer,
                setLoadingCustomer,
            }}
        >
            {props.children}
        </ThreadContext.Provider>
    );
};

export const useThread = () => {
    const context = useContext(ThreadContext);
    if (!context) {
        throw new Error('useThread must be used within a ThreadProvider');
    }
    return context;
};
