import ChevronDownIcon from '@icons/arrows/chevron-down.svg';
import ChevronUpIcon from '@icons/arrows/chevron-up.svg';
import MessageTextSquare02Icon from '@icons/communication/message-text-square-02.svg';
import DotsVerticalIcon from '@icons/general/dots-vertical.svg';
import Trash02Icon from '@icons/general/trash-02.svg';
import StopWatchIcon from '@icons/time/stopwatch.svg';
import { Trans } from '@mbarzda/solid-i18next';
import { createResizeObserver } from '@solid-primitives/resize-observer';
import { A } from '@solidjs/router';
import { For, Show, Suspense, createSignal, onMount } from 'solid-js';
import { Skeleton } from '~/components/Skeleton';
import { Collapsible, Menu, Tooltip } from '~/components/ui';
import { RecentThread, useRecentThreads } from './RecentThreadsContext';

import styles from '../../layout/SidebarList.module.css';

const IS_OPEN_BY_DEFAULT = true;

const Thread = (props: { thread: RecentThread }) => {
    const { removeThread } = useRecentThreads();

    let ref!: HTMLAnchorElement;

    const [isTruncated, setIsTruncated] = createSignal(false);

    onMount(() => {
        createResizeObserver(ref, (_, el) => {
            if (el.parentNode) {
                setIsTruncated(
                    (el.parentNode as HTMLElement).offsetWidth <
                        (el.parentNode as HTMLElement).scrollWidth,
                );
            }
        });
    });

    return (
        <div class={styles.element}>
            <Tooltip.Root
                positioning={{
                    placement: 'top',
                    offset: {
                        mainAxis: 0,
                        crossAxis: 60,
                    },
                }}
            >
                <Tooltip.Trigger class="w-full truncate text-left">
                    <A
                        ref={ref}
                        href={`/threads/${props.thread.id}`}
                        class={styles.elementLink}
                        activeClass={styles.elementLinkActive}
                    >
                        <MessageTextSquare02Icon
                            class={`${styles.elementLinkIcon} scale-x-[-1]`}
                            aria-label="chat"
                        />
                        {props.thread.title}
                    </A>
                </Tooltip.Trigger>
                <Show when={isTruncated()}>
                    <Tooltip.Positioner>
                        <Tooltip.Content>
                            <Tooltip.Arrow>
                                <Tooltip.ArrowTip />
                            </Tooltip.Arrow>
                            {props.thread.title}
                        </Tooltip.Content>
                    </Tooltip.Positioner>
                </Show>
            </Tooltip.Root>
            <Menu.Root
                positioning={{ placement: 'right' }}
                onSelect={async (e) => {
                    if (e.value === 'delete') {
                        await removeThread(props.thread.id);
                    }
                }}
                size="xs"
            >
                <Menu.Trigger aria-label="thread actions">
                    <div class={styles.menuTrigger}>
                        <DotsVerticalIcon
                            class={styles.menuTriggerIcon}
                            aria-label="dots"
                        />
                    </div>
                </Menu.Trigger>
                <Menu.Positioner>
                    <Menu.Content>
                        <Menu.Item id="delete">
                            <div class={styles.menuItem}>
                                <Trans key="layout.conversations.delete" />
                                <Trash02Icon aria-label="trash" />
                            </div>
                        </Menu.Item>
                    </Menu.Content>
                </Menu.Positioner>
            </Menu.Root>
        </div>
    );
};

export default (props: { class?: string }) => {
    const [expanded, setExpanded] = createSignal(IS_OPEN_BY_DEFAULT);
    const { threads } = useRecentThreads();

    return (
        <Collapsible.Root
            class={`group ${props.class ? props.class : ''}`}
            lazyMount
            onOpenChange={() => setExpanded(true)}
            onExitComplete={() => setExpanded(false)}
            open={expanded()}
        >
            <Collapsible.Trigger class={`group ${styles.trigger}`}>
                <div
                    class={styles.triggerElement}
                    classList={{ 'font-semibold': expanded() }}
                >
                    <StopWatchIcon
                        class={styles.triggerElementWatch}
                        aria-label="conversations"
                    />
                    <Trans key="layout.conversations.title" />
                    <div class={styles.triggerElementChevron}>
                        <Show
                            when={expanded()}
                            fallback={
                                <ChevronDownIcon
                                    class="h-4"
                                    aria-label="chevron down"
                                />
                            }
                        >
                            <ChevronUpIcon
                                class="h-4"
                                aria-label="chevron up"
                            />
                        </Show>
                    </div>
                </div>
            </Collapsible.Trigger>
            <Suspense fallback={<Skeleton />}>
                <Collapsible.Content>
                    <div class={`flex flex-col gap-1 ${styles.collapsible}`}>
                        <For each={threads()}>
                            {(thread) => <Thread thread={thread} />}
                        </For>
                    </div>
                </Collapsible.Content>
            </Suspense>
        </Collapsible.Root>
    );
};
