import { UUID } from '@zelros/interfaces-utils';

export interface ITrackingEvent {
    id: UUID;
    type: TrackingEventType;
    source: TrackingEventSource;
    context: Record<string, any>;
    date: Date;
}

export enum TrackingEventSource {
    Assistant = 'assistant',
    LiveExtractor = 'live_extractor',
}

export enum TrackingEventKnownType {
    DisplayAssistant = 'display_assistant',
    HideAssistant = 'hide_assistant',
    DisplayNotification = 'display_notification',
    ClickNotification = 'click_notification',
    CloseNotification = 'close_notification',
}

export type TrackingEventType = TrackingEventKnownType | string;
