import { QuestionsBlock } from '../questions-block';

export interface IStaticLinkSectionLink {
    uri: string;
    title: string;
    type: 'link' | 'knowledge';
}

export interface IStaticLinkSection {
    title: string;
    color?: string;
    expanded?: boolean;
    links: IStaticLinkSectionLink[];
}

/**
 * Minimal config that should be fetched by the Zelros App at the very beginning.
 * Doesn't require any authentication
 */
export interface FrontendBootstrapConfig {
    authentication: {
        options: {
            realm: string;
            clientId: string;
        };
        iframe: {
            openKeycloakInPopup: OpenKeycloakInPopupType;
        };
        defaultIdp: string;
    };
    analytics: {
        id: string;
    };
    logging: {
        enabled: boolean;
    };
    caching: {
        enabled: boolean;
    };
}
/**
 * Config that should be fetched by the Zelros App after the user is authenticated.
 * Requires authentication
 * At the moment, all users would be served the same config but there is no guarantee that it will always be the case.
 */
export interface FrontendConfig extends FrontendPersonalizedConfig {
    magicAnswer: {
        discussion: {
            userGroupIds: string[];
        };
    } & FrontendPersonalizedConfig['magicAnswer'];
}

export interface FrontendPersonalizedConfig {
    homepage: {
        questions: {
            blocks: QuestionsBlock[];
        };
        lookAndFeel: {
            title: string | null;
            header: {
                logo: string | null;
            };
            homepage: {
                logo: string | null;
            };
            searchBar: {
                logo: string | null;
            };
            colors: {
                main: string;
            };
        };
    };
    sidebar: {
        staticLinks: IStaticLinkSection[];
    };
    magicAnswer: {
        discussion: {
            enabled: boolean;
        };
        streaming: {
            enabled: boolean;
        };
        socketio: {
            websockets: boolean;
        };
        sources: {
            tags: {
                enabled: boolean;
            };
        };
        feedback: {
            enabled: boolean;
            negativeReasons: string[];
            comment: {
                enabled: boolean;
                mandatory: boolean;
            };
        };
        lookAndFeel: {
            text: {
                h1: {
                    color: string;
                };
                h2: {
                    color: string;
                };
                h3: {
                    color: string;
                };
                h4: {
                    color: string;
                };
                href: {
                    color: string;
                };
                ul: {
                    color: string;
                };
                strong: {
                    color: string;
                };
            };
        };
    };
    recommendation: {
        tab: {
            enabled: boolean;
        };
        openingPolicy: RecommendationOpeningPolicy;
        lookAndFeel: {
            products: {
                id: string;
                name: string;
                color: string | null;
                logo: string | null;
            }[];
        };
    };
    warning: {
        tab: {
            enabled: boolean;
        };
    };
}

export enum OpenKeycloakInPopupType {
    None = 'none',
    Tab = 'tab',
    Window = 'window',
}

export enum RecommendationOpeningPolicy {
    None = 'none',
    All = 'all',
    First = 'first',
}
