import type {
    Opportunity,
    Recommendation,
} from '@zelros/recommendations-interfaces';
import { useLogging } from '~/logging/LoggingContext';

export interface OpportunityDisplay {
    id: string;
    recommendations: RecommendationDisplay[];
    magic: boolean;
}

export interface SellingPointDisplay {
    id: string;
    title: string;
    illustration: string;
    product: ProductDisplay;
}

export interface ProductDisplay {
    id: string;
    name: string;
    logo: string | null;
    color: string | null;
}

export interface RecommendationDisplay {
    id: string;
    title: string;
    catchPhrase?: string;
    product: ProductDisplay;
    sellingPoints: SellingPointDisplay[];
    magic: boolean;
}

export const toRecommendationDisplay = (
    opportunity: Opportunity,
    recommendation: Recommendation,
    productsMap: Map<string, ProductDisplay>,
): RecommendationDisplay => {
    const { log } = useLogging();

    const catchPhrase =
        opportunity.lifeEventDisplay.lifeEvent.productSettings.find(
            (p) => p.id === recommendation.product.id,
        )?.catchPhrase ?? undefined;

    let product = productsMap.get(recommendation.product.id);
    if (!product) {
        log(
            `Product not found in config: ${recommendation.product.id}. Will fallback to default logo and color`,
        );
        product = {
            id: recommendation.product.id,
            name: recommendation.product.name,
            logo: null,
            color: null,
        };
    }

    return {
        id: recommendation.id,
        title: opportunity.lifeEventDisplay.title,
        catchPhrase,
        product,
        sellingPoints: recommendation.sellingPointDisplays.map((sp) => {
            return {
                id: sp.id,
                title: sp.title,
                illustration: sp.illustration,
                product,
            };
        }),
        magic: !!opportunity.lifeEventDisplay.data.magic,
    };
};

export const toOpportunityDisplay = (
    opportunity: Opportunity,
    productsMap: Map<string, ProductDisplay>,
): OpportunityDisplay => {
    return {
        id: opportunity.id,
        recommendations: opportunity.recommendations.map((recommendation) =>
            toRecommendationDisplay(opportunity, recommendation, productsMap),
        ),
        magic: !!opportunity.lifeEventDisplay.data.magic,
    };
};
