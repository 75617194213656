import { createContext, JSX, onCleanup, onMount } from 'solid-js';
import { useBootstrapConfig } from '~/config/BootstrapConfigProvider';
import { useLogging } from '~/logging/LoggingContext';

const CachingContext = createContext();

export const CachingProvider = (props: { children: JSX.Element }) => {
    const bootstrapConfig = useBootstrapConfig();
    const { log } = useLogging();

    const cachingEnabled = bootstrapConfig.caching.enabled;

    // Retrieve cached URL from sessionStorage
    const cachedURL = sessionStorage.getItem('cachedURL') || null;

    if (cachingEnabled) {
        log('CachingProvider: Caching enabled');
        if (cachedURL) {
            log('CachingProvider: Restoring cached URL', cachedURL);
            window.history.replaceState(null, '', cachedURL);
        } else {
            log('CachingProvider: No cached URL found');
        }
    } else {
        log('CachingProvider: Caching disabled');
        sessionStorage.removeItem('cachedURL'); // Just in case
    }

    const handleURLChange = () => {
        log('CachingProvider: URL changed');
        const url = window.location.pathname + window.location.hash;
        sessionStorage.setItem('cachedURL', url);
    };

    const handlePushStateChange = (...args: any[]) => {
        const url = args[2];
        log(`CachingProvider: URL changed via pushState`, url);
        sessionStorage.setItem('cachedURL', url);
    };

    onMount(() => {
        // Listen to URL changes in VanillaJS (we're outside the SolidJS Router)

        if (cachingEnabled) {
            // Add popstate listener (when using the Back / Forward buttons of the browser)
            window.addEventListener('popstate', handleURLChange);

            // Wrap History API methods (when using pushState / replaceState: regular navigation)
            const originalPushState = history.pushState;
            history.pushState = function (...args) {
                originalPushState.apply(this, args);
                handlePushStateChange(...args);
            };

            onCleanup(() => {
                log('CachingProvider: Cleaning up listeners');
                window.removeEventListener('popstate', handleURLChange);
                history.pushState = originalPushState;
            });
        }
    });

    return (
        <CachingContext.Provider value={{}}>
            {props.children}
        </CachingContext.Provider>
    );
};
