export function runAsyncNoAwait(
    promise: Promise<unknown>,
    logger?: { log: (message: string) => void; error: (e: Error) => void },
): void {
    promise
        .then((message) => {
            if (logger && typeof message === 'string') {
                logger.log(message);
            }
        })
        .catch((e) => {
            if (logger) {
                e.message = `${e.message} (/!\\ Half-dangling promise /!\\)`;
                logger.error(e);
            }
        });
}

export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
