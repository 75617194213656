import {
    WarningDisplay,
    WarningDisplayDTO,
    warningDisplayFromDTO,
} from '@zelros/recommendations-interfaces';
import {
    isThreadToolMessage,
    ThreadToolMessage,
} from '@zelros/standalone-interfaces';
import { get as levenshtein } from 'fast-levenshtein';
import {
    Accessor,
    batch,
    createContext,
    createEffect,
    createSignal,
    JSX,
    on,
    untrack,
    useContext,
} from 'solid-js';
import { useLogging } from '~/logging/LoggingContext';
import { useThread } from '../../ThreadContext';
import { isEqual } from 'lodash-es';

const GET_WARNINGS_TOOL_NAME = 'get_customer_warnings';

type WarningContextType = {
    getWarning: (id: string) => WarningDisplay;
    latestWarnings: Accessor<WarningDisplay[]>;
    warningsFetched: Accessor<boolean>;
};

const WarningContext = createContext<WarningContextType>();

interface WarningProviderProps {
    children: JSX.Element;
}

export const WarningsProvider = (props: WarningProviderProps) => {
    const { log } = useLogging();
    const { threadMessages } = useThread();
    const [warnings, setWarnings] = createSignal<WarningDisplay[]>([]);
    const [latestWarnings, setLatestWarnings] = createSignal<WarningDisplay[]>(
        [],
    );
    const [warningsFetched, setWarningsFetched] = createSignal(false);

    const handleWarnings = (warningMessages: ThreadToolMessage[]) => {
        /**
         * Build list of warnings from warning messages
         * We filter out the duplicates, keeping only the last message for each warning
         */
        const warningsFromMessages = warningMessages
            .flatMap((message) =>
                (message.metadata.value as WarningDisplayDTO[]).map(
                    warningDisplayFromDTO,
                ),
            )
            .filter((warning, index, self) => {
                const lastIndex = self.reduce(
                    (lastIndex, current, i) =>
                        current.id === warning.id ? i : lastIndex,
                    -1,
                );
                return index === lastIndex;
            });

        // Only update the warnings if they have changed
        const ws = untrack(warnings);
        if (!isEqual(ws, warningsFromMessages)) {
            log('WarningsContext: updating warnings...');
            setWarnings(warningsFromMessages);
        }

        /**
         * Update the latest warnings with the last message warnings,
         * if they are not already in the latestWarnings
         */
        if (warningMessages.length > 0) {
            const lastMessageWarnings = warningMessages[
                warningMessages.length - 1
            ].metadata.value as WarningDisplayDTO[];

            // Only update the latestWarnings if they have changed
            const latestWs = untrack(latestWarnings);
            if (!isEqual(latestWs, lastMessageWarnings)) {
                log('WarningsContext: Updating latest warnings...');
                setLatestWarnings(
                    lastMessageWarnings.map(warningDisplayFromDTO),
                );
            }
        }
    };

    createEffect(
        on(threadMessages, () => {
            const warningMessages = threadMessages().filter(
                (message) =>
                    isThreadToolMessage(message) &&
                    message.metadata.name === GET_WARNINGS_TOOL_NAME,
            );

            if (warningMessages.length > 0) {
                setWarningsFetched(true);
            } else {
                batch(() => {
                    setWarningsFetched(false);
                    setLatestWarnings([]);
                });
            }
            handleWarnings(warningMessages as ThreadToolMessage[]);
        }),
    );

    const getWarning = (id: string): WarningDisplay => {
        const ws = untrack(warnings);

        const warning = ws.find((w) => levenshtein(w.id, id) < 3);

        if (!warning) {
            throw new Error(`Warning ${id} not found`);
        }

        return warning;
    };

    return (
        <WarningContext.Provider
            value={{
                getWarning,
                latestWarnings,
                warningsFetched,
            }}
        >
            {props.children}
        </WarningContext.Provider>
    );
};

export const useWarnings = () => {
    const context = useContext(WarningContext);
    if (!context) {
        throw new Error('useWarnings must be used within a WarningsProvider');
    }
    return context;
};
