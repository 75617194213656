import type { ThreadDTO } from '@zelros/standalone-interfaces';
import {
    Accessor,
    createContext,
    createResource,
    createSignal,
    type JSX,
    type Resource,
    untrack,
    useContext,
} from 'solid-js';
import * as _ from 'lodash';
import { useAuth } from '~/auth/AuthContext';
import { BASE_URL } from '~/Constants';

interface RecentThreadsContextType {
    threads: Resource<RecentThread[]>;
    addThread: (thread: RecentThread) => void;
    removeThread: (threadId: string) => Promise<void>;
    removedThreadId: Accessor<string | undefined>;
}

export interface RecentThread {
    id: string;
    title: string;
}

const RecentThreadsContext = createContext<RecentThreadsContextType>();

export const RecentThreadsProvider = (props: { children: JSX.Element }) => {
    const { authHeader } = useAuth();
    const [removedThreadId, setRemovedThreadId] = createSignal<string>();

    const baseUrl = `${BASE_URL}/api/standalone/threads`;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getThreads = async (): Promise<RecentThread[]> => {
        const res = await fetch(baseUrl, {
            cache: 'no-store',
            headers: {
                ...authHeader(),
            },
        });
        const data = (await res.json()) as ThreadDTO[];
        const newThreads = data
            .map((thread) => {
                const messages = thread.messages.filter(
                    (message) => message.message.role === 'user',
                );
                if (messages.length === 0) {
                    return null;
                }
                return {
                    id: thread.id,
                    title: messages[0].message.content as string,
                };
            })
            .filter((thread) => thread !== null);

        // Merge new threads with existing threads
        const existingThreads = untrack(threads) ?? [];
        return _.uniqBy([...existingThreads, ...newThreads], 'id');
    };

    const deleteThread = async (id: string) => {
        await fetch(`${baseUrl}/${id}`, {
            method: 'DELETE',
            headers: {
                ...authHeader(),
            },
        });
    };

    const [threads, { mutate }] = createResource(getThreads);

    const addThread = (thread: RecentThread) => {
        mutate((prev) => {
            if (prev) {
                return _.uniqBy([thread, ...prev], 'id');
            }
            return [thread];
        });
    };

    const removeThread = async (threadId: string) => {
        await deleteThread(threadId);
        mutate((prev) => {
            if (prev) {
                return prev.filter((thread) => thread.id !== threadId);
            }
            return [];
        });
        setRemovedThreadId(threadId);
    };

    return (
        <RecentThreadsContext.Provider
            value={{ threads, addThread, removeThread, removedThreadId }}
        >
            {props.children}
        </RecentThreadsContext.Provider>
    );
};

export const useRecentThreads = () => {
    const context = useContext(RecentThreadsContext);
    if (!context) {
        throw new Error(
            'useThread must be used within a RecentThreadsProvider',
        );
    }
    return context;
};
